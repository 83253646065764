import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totens Digitais"
      description="Os totens digitais desenvolvidos na iTS Enterprise Innovation tem tecnologia nacional, robustos e de alto desempenho. Oferecemos linhas de totem de autoatendimento, totem tablet e Mesas Touch Screen."
      canonical="https://itstecnologia.digital/totens-digitais/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height totens-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Totens Digitais</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" title="Home">Home</Link></li>
                <li className="breadcrumb-item active">Totens Digitais</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <h2 className="toten-subtitle">
                Conheça as linhas de totem tablet, totem de autoatendimento, mesa touch screen e totem carregador.
                </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Link to="/totens-digitais/totens-tablet/">
                <div className="row product-wrapper for-kiosk">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box">
                        <div className="logo-smart">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Totens Tablet</h3>
                      <p>
                        Totens digitais interativos de baixo custo e pequenos formatos, utilizados em áreas
                        com limitação de espaço físico ou para solução de baixa complexidade.
                                </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-lg-6">
              <Link to="/totens-digitais/mesas-touch-screen/">
                <div className="row product-wrapper for-kiosk">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box">
                        <div className="logo-smart">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/mesa-digital.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Mesas Touch Screen</h3>
                      <p>
                        Mesa Touch Screen Interativas em grandes formatos possibilitando ampla visualização
                        das informações apresentadas. Muito utilizada para apresentação de conteúdo.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Link to="/totens-digitais/totens-autoatendimento/">
                <div className="row product-wrapper for-kiosk">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box">
                        <div className="logo-smart">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-pro.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Totem de Autoatendimento</h3>
                      <p>
                        Totens digitais interativos de alto desempenho com foco na interação com usuários,
                        principalmente relacionado a atendimento.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-lg-6">
              <Link to="/totens-digitais/totens-carregadores/">
                <div className="row product-wrapper for-kiosk">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box">
                        <div className="logo-smart">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-carregador.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Totens Carregadores</h3>
                      <p>
                        Totens carregadores de bateria para dispositivos móveis, com associação de mída
                        indoor.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="explanation-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="explanation-title">
                  <div className="text-center">
                    Fabricamos totens digitais de autoatendimento que conectam sua
                    empresa a seus clientes, conciliando agilidade, disponibilidade e economia de recursos.
                  </div>
                  <span>
                    Além da linha de totem digital oferecida, desenvolvemos projetos
                    de totens digitais de autoatendimento personalizados de acordo com a necessidade de cada projeto.
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 social-content-parallax alt-2">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="social-card alt-2">
                      <span>1</span>
                      <p>Totem de autoatendimento com Tecnologia Nacional, facilitando a reposição de peças.</p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="social-card alt-2">
                      <span>2</span>
                      <p>Totens digitais com alto desempenho, robustos, confererindo resistência e
                        durabilidade.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="social-card alt-2">
                      <span>3</span>
                      <p>Projeto de Totem Digital personalizável considerando a necessidade do cliente.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p>
                As ideias são perfeitamente integradas ao nosso processo de
                design e desenvolvimento para produzir totem de autoatendimento robusto, elegante e de alta performance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Automatize as soluções do seu negócio!</h3>
              </div>
              <div className="col-12 col-lg-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment>
);
