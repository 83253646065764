import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totens Carregadores"
      description="Conheça os Totens Carregadores de celular fabricados pela iTS Enterprise Innovation. São ótimas opções para alocação em locais com fluxo e permanência de pessoas, dado aos cabos serem triplos USB, conferem versatilidade ao totem que possui seis nichos individuais com chave."
      canonical="https://itstecnologia.digital/totens-digitais/totens-carregadores/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height totens-charger-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Totens Carregadores</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">Home</Link>
                </li>
                <li className="breadcrumb-item"><Link to="/totens-digitais/" title="Totens Digitais">
                  Totens Digitais
                  </Link>
                </li>
                <li className="breadcrumb-item active">Totens Carregadores</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg about-content no-bg-img p-bottom-70">
        <div className="container">
          <h2>Totens Carregadores</h2>
          <div className="row gutters">
            <div className="col-12 toten-explanation">
              <p>
                Atualmente a utilização de eletrônicos principalmente <span>dispositivos móveis</span> tornou-se
                essencial pois
                centralizamos nestes equipamentos todas as informações e controles necessários no nosso dia a dia e
                consideramos que a falta destes recursos limita nossas atividades.
                </p>
              <p>
                Os totens carregadores são boas opções para alocação em <span> locais</span> onde as pessoas podem
                permanecer um tempo significativo.
                </p>
              <p>
                Os totens carregadores possuem <span> 6 nichos individuais </span>, cada nicho é composto por <span>
                  cabos triplos de USB</span>,
flexibilizando a diversidades de tipos de equipamentos eletrônicos existentes.
                </p>
              <p>
                As portas individuais com <span> chaves</span> garantem a segurança do equipamento enquanto está
                sendo carregado.
                </p>
              <p>
                Os totens carregadores além de oferecer uma comodidade ao cliente, possibilita a aplicação de
                identidade visual da sua empresa, através de plotagem, proporcionando então benefícios ao seu
                cliente e exposição da sua marca.
                </p>
            </div>
            <div className="col-12">
              <h2 className="title-alt">Destaques</h2>
              <ul className="check-list-structure feat">
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Comodidade do Cliente;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Segurança;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Exposição da Marca;</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section gallery-content">
        <div className="container">
          <h2>Galeria</h2>
          <div className="gallery-row">
            <div className="gallery-col gallery-col-alt">
              <img title="Totem Carregador, plotado para a John Deere, lado esquerdo" src={require("../../assets/galleries/charger/totem-carregador-de-celular-01.jpg")} alt="Totem carregador de celular plotado para a John Deere, vista lateral esquerda." />
            </div>
            <div className="gallery-col gallery-col-alt">
              <img title="Totem Carregador, plotado para a John Deere, lado direito" src={require("../../assets/galleries/charger/totem-carregador-de-celular-02.jpg")} alt="Totem carregador de celular plotado para a John Deere, vista lateral direita." />
            </div>
            <div className="gallery-col gallery-col-alt">
              <img title="Totem Carregador, plotado para a John Deere, em diagonal" src={require("../../assets/galleries/charger/totem-carregador-de-celular-03.jpg")} alt="Totem carregador de celular plotado para a John Deere, vista lateral." />
            </div>
          </div>
        </div>
      </div>

      <div className="section dark-bg padding-70 resources-content no-bg-img">
        <div className="container">
          <div className="row">
            <h2>Motivos para adquirir</h2>
          </div>
          <div className="row gutters">
            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/anti-impacto.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Exposição da sua marca</h3>
                <p>
                  A identidade visual da sua empresa pode aplicada através de plotagem e aplicação no totem
                  carregador, dando destaque à sua marca.
                    </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/alto-desempenho.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Comodidade para seus clientes</h3>
                <p>Em um mundo que está sempre conectado, alguns minutos a mais de bateria podem fazer toda a
                        diferença para seus clientes. Mostre a seu cliente que sua empresa se preocupa com ele.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/bivolt.png" )}alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Segurança</h3>
                <p>As portas individuais com chave permitem que o cliente possa deixar seu dispositivo carregando e
                        circular livremente pela empresa.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Entre em contato, traga seu projeto para a iTS!</h3>
              </div>
              <div className="col-12 col-md-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg padding-70 resources-content">
        <div className="container">
          <h2 className="dark-red-color">Especificações Técnicas</h2>
          <table className="table-striped table">
            <thead>
              <tr>
                <th>Itens</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gabinete</td>
                <td>Metal, disponível nas cores Preto ou Branco</td>
              </tr>
              <tr>
                <td>Proteções do gabinete</td>
                <td>Maresia, Estática e Corrosão</td>
              </tr>
              <tr>
                <td>Nichos</td>
                <td>6 nichos individuais com chaves</td>
              </tr>
              <tr>
                <td>Cabos USB</td>
                <td>Cabos triplos, saída para Apple, mini USB e USB tipo c</td>
              </tr>
              <tr>
                <td>Voltagem</td>
                <td>Bivolt automático (110v / 220v)</td>
              </tr>
              <tr>
                <td>Dimensões</td>
                <td>153 cm (altura) x 25 cm (frente) x 30 cm (lateral)</td>
              </tr>
              <tr>
                <td>Peso Aproximado</td>
                <td>9kg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Tablet</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de baixo custo e pequenos formatos, utilizados em áreas
                        com limitação de espaço físico ou para solução de baixa complexidade.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-autoatendimento/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-pro.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totem de Autoatendimento</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de alto desempenho com foco na interação com usuários,
                        principalmente relacionado a atendimento.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/mesas-touch-screen/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/mesa-digital.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Mesas Touch Screen</h3>
                      <p className="products-recall-description">
                        Mesa Touch Screen Interativas em grandes formatos possibilitando ampla visualização
                        das informações apresentadas. Muito utilizada para apresentação de conteúdo.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div >
  </Fragment >
);
