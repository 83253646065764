import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Mesas Touch Screen"
      description="Conheça as Mesas Touch Screen fabricadas pela iTS Enterprise Innovation. A mesa interativa touch screen possui tela multi-touch com 40 polegadas, voltada a soluções de grande apelo visual e que requer interatividade, propõe uma experiência única aos usuários."
      canonical="https://itstecnologia.digital/totens-digitais/mesas-touch-screen/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height totens-table-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Mesas Touch Screen</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/" title="Totens Digitais">
                    Totens Digitais
                  </Link>
                </li>
                <li className="breadcrumb-item active">Mesas Touch Screen</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg about-content no-bg-img p-bottom-70">
        <div className="container">
          <h2>Mesas Touch Screen</h2>
          <div className="row gutters">
            <div className="col-12 toten-explanation">

              <p>
                As Mesas Touch Screen com sua <span>tela multi-touch</span> e com 40 polegadas possibilita que<span>
                  soluções</span> que demandem
                  grande apelo visual e possam requerer interatividade se tornem <span>atrativas</span>,
possibilitando uma experiência única aos usuários.
                </p>
              <p>
                Esta solução é procurada para <span>aplicação de catálogos de produtos, divulgação de imóveis,
                mapas</span>, entre outras soluções.
                </p>
              <p>
                A possibilidade de aplicação de identidade visual através de plotagem, possibilita ainda mais o
                destaque da solução aplicada.
                </p>
              <p>
                Algumas soluções não demandam de apelo visual e sim de espaço para <span>apresentação de
                informações</span> como mapas e diagramas para equipes de manutenção de chão de fábrica,
                possibilitando maior visualização e objetividade e consequente produtividade nas manutenções
                realizadas pela equipe.
                </p>
            </div>
            <div className="col-12">
              <h2 className="title-alt">Destaques</h2>
              <ul className="check-list-structure feat">
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  Resistentes;
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  Seguras;
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  Apelo Visual;
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  Amplitude na visualização das informações;
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  Alto Desempenho;
                </li>
              </ul>
            </div>
          </div >
        </div >
      </div >

      <div className="section gallery-content">
        <div className="container">
          <h2>Galeria</h2>
          <div className="gallery-row">
            <div className="gallery-col">
              <img title="Mesa Touch Screen, junto de um Totem de Autopagamento" src={require("../../assets/galleries/table/totem-autoatendimento-com-pinpad-e-mesa-touch-screen.jpg")} alt="Totem de autopagamento com pinpad e impressora acomplado ao lado de uma mesa touch screen." />
              <img title="Mesa Touch Screen, sendo apresentada em evento" src={require("../../assets/galleries/table/utilizando-mesa-touch-screen.jpg")} alt="Mesa touch screen sendo apresentada em um evento." />
            </div>
            <div className="gallery-col">
              <img title="Mesa Touch Screen, plotada para a Paraná Clínicas, junto de um Totem de Autoatendimento" src={require("../../assets/galleries/table/mesa-touch-screen-e-totens-de-autoatendimento.jpg")} alt="Mesa touch screen plotada e ao lado totem de autoatendimento plotados para Paraná Clínicas." />
              <img title="Mesas Touch Screen, sendo preparadas para envio" src={require("../../assets/galleries/table/mesa-touch-screen-sem-plotagem.jpeg")} alt="Mesas touch screen sendo preparadas para envio ao cliente. " />
            </div>
            <div className="gallery-col">
              <img title="Mesa Touch Screen, alocada na Leroy Merlim" src={require("../../assets/galleries/table/mesa-touch-screen-alocada-em-espaço.jpg")} alt="Mesa touch screen alocada na loja Leroy Merlim." />
            </div>
            <div className="gallery-col">
              <img title="Mesa Touch Screen, interativa com aplicação" src={require("../../assets/galleries/table/mesa-touch-screen-02.jpg")} alt="Mesa touch screen interativa com aplicação." />
            </div>
          </div>
        </div>
      </div>

      <div class="section dark-bg padding-70 resources-content no-bg-img">
        <div class="container">
          <div class="row">
            <h2>Características</h2>
          </div>
          <div class="row gutters">
            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/anti-impacto.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Tela com Proteção Anti-Impacto</h3>
                <p>Proteção de tela para suportar impactos fortes, aumentando o tempo de vida do equipamento e
                        reduzindo manutenções.</p>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/alto-desempenho.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Alto Desempenho</h3>
                <p>Equipamento de alto desempenho, contando com armazenamento SSD para maior agilidade e comodidade
                        no atendimento.</p>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/bivolt.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Bivolt Automático</h3>
                <p>Pensando na variedade de ambientes, os equipamentos possuem fonte bivolt automática, se adaptando
                        a redes 110v ou 220v sem necessidade de ajuste manual.</p>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/chave-liga-desliga.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Chave Liga/Desliga</h3>
                <p>Utilização de chave para ligar/desligar o equipamento, que pode ser removida após o uso para
                        limitar o uso somente por pessoas autorizadas.</p>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/suporte.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Tela</h3>
                <p>Tela touch screen possibilitando ampla visualização e interatividade com as informações
                        apresentadas.</p>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 r-item">
              <div class="icon">
                <img src={require("../../assets/icons/opcionais.png")} alt="complementary icon" />
              </div>
              <div class="content">
                <h3>Opcionais</h3>
                <p>Diversa gama de opcionais para complementar seu equipamento de autoatendimento.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Entre em contato, traga seu projeto para a iTS!</h3>
              </div>
              <div className="col-12 col-md-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section white-bg padding-70 no-padding-bottom resources-content">
        <div class="container">
          <h2 className="dark-red-color">Especificações Técnicas</h2>
          <table class="table-striped table">
            <thead>
              <tr>
                <th>Itens</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gabinete</td>
                <td>Metal, disponível nas cores Preto ou Branco <br />Ajuste de inclinação com 5 posições
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Proteções do gabinete</td>
                <td>Maresia, Estática e Corrosão</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>40 polegadas com proteção anti impacto <br />50 ou 60 polegadas com proteção anti-impacto</td>
              </tr>
              <tr>
                <td>Touch Screen</td>
                <td>2 toques <br /> 3 a 10 toques <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Processador</td>
                <td>Dual Core ou superior</td>
              </tr>
              <tr>
                <td>Memória</td>
                <td>4GB</td>
              </tr>
              <tr>
                <td>Armazenamento</td>
                <td>SSD 1200GB</td>
              </tr>
              <tr>
                <td>Voltagem</td>
                <td>Bivolt automático (110v / 220v)</td>
              </tr>
              <tr>
                <td>Ethernet</td>
                <td>Ethernet Gigabit - 1.000Mbps <br /> WiFi – 150Mbps
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Câmera</td>
                <td>Full HD <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Som</td>
                <td>Saída de áudio traseira</td>
              </tr>
              <tr>
                <td>Impressora</td>
                <td>Térmica 80 mm com corte automático <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Suporte para PinPad</td>
                <td>Gertec PPC 910, Ingênico IPP 320 ou IPP 350 <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Sistema Operacional</td>
                <td>Linux Ubuntu <br /> Microsoft Windows <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Features Específicos</td>
                <td>Leitor Cód. de Barras <small>(OPCIONAL)</small> <br /> Leitor Tarja
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Pés</td>
                <td>Pés fixos com regulagem de altura <br /> Rodízio em silicone com trava
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Peso Aproximado</td>
                <td>75kg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Tablet</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de baixo custo e pequenos formatos, utilizados em áreas
                        com limitação de espaço físico ou para solução de baixa complexidade.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-autoatendimento/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-pro.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totem de Autoatendimento</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de alto desempenho com foco na interação com usuários,
                        principalmente relacionado a atendimento.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-carregadores/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-carregador.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Carregadores</h3>
                      <p className="products-recall-description">
                        Totens carregadores de bateria para dispositivos móveis, com associação de mída
                        indoor.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div >
  </Fragment >
);
