import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  height: 500
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={18}
        style={mapStyles}
        initialCenter={{
          lat: -25.45761,
          lng: -49.28819
        }}
      >
        <Marker
          title={"iTS Enterprise Innovation"}
          name={"iTS Enterprise Innovation"}
          icon={require("../../assets/its-marker.png")}
          // position={{ lat: -25.45761, lng: -49.28819 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyACr7ZCDd_SeujNVoC9-cqo-lZFfZ8l9JI"
})(MapContainer);
