const CasesTotensData = [
  {
    title: "Havan",
    image: require("../../assets/supporters/havan_logo.png"),
    link: "/cases/totem-digital/havan/",
    description: "Totem digital de autoatendimento com solução de Gestão de Filas."
  },
  {
    title: "Paraná Clínicas",
    image: require("../../assets/supporters/parana_logo.png"),
    link: "/cases/totem-digital/parana-clinicas/",
    description: "Totem digital de autoatendimento com câmera para biometria facial."
  },
  {
    title: "Leroy Merlin",
    image: require("../../assets/supporters/leroy_merlin_logo.png"),
    link: "/cases/totem-digital/leroy-merlin/",
    description: "Totem digital interativo com aplicativo de divulgação de promoções."
  },
  {
    title: "Votorantim",
    image: require("../../assets/supporters/votorantim_logo.png"),
    link: "/cases/totem-digital/votorantim/",
    description: "Totem digital com aplicativo para apontamento de Ordem de Serviço."
  },
  {
    title: "TicNova",
    image: require("../../assets/supporters/ticnova_logo.png"),
    link: "/cases/totem-digital/ticnova/",
    description: "Totem digital com aplicativo de Checkin e Pesquisa de Satisfação."
  },
  {
    title: "Condor",
    image: require("../../assets/supporters/condor_logo.png"),
    link: "/cases/totem-digital/condor/",
    description: "Totem digital de autoatendimento para cadastramento de clientes."
  },
];

const CaseAppsData = [
  {
    title: "Idelo",
    image: require("../../assets/cases/idelo-logo.png"),
    link: "/cases/aplicativo-mobile/idelo/",
    description: "Plataforma digital que conecta empreendedores a Investidores",
    playStore: "https://play.google.com/store/apps/details?id=br.com.squarebits.idelo",
    appStore: "",
  },
  {
    title: "Ninky",
    image: require("../../assets/cases/ninky-logo.png"),
    link: "/cases/aplicativo-mobile/ninky/",
    description: "Plataforma de divulgação dos tatuadores, seus estilos e trabalhos",
    playStore: "https://play.google.com/store/apps/details?id=br.com.ninky",
    appStore: "https://apps.apple.com/br/app/ninky/id1452245090",
  },
  {
    title: "LausGirl & Co",
    image: require("../../assets/cases/laus-logo.webp"),
    link: "/cases/aplicativo-mobile/laus/",
    description: "Plataforma de gestão de revendedores, produtos e pedidos",
    playStore: "https://play.google.com/store/apps/details?id=br.com.squarebits.lausgirl",
    appStore: "https://apps.apple.com/br/app/lausgirl-co/id1378379088",
    laus: true
  },
  {
    title: "E-gorjeta",
    image: require("../../assets/cases/egorjeta-logo.png"),
    link: "/cases/aplicativo-mobile/egorjeta/",
    description: "Plataforma para pagamento de gorjeta para profisssionais",
    playStore: "https://play.google.com/store/apps/details?id=br.com.egorjeta",
    appStore: "https://apps.apple.com/br/app/e-gorjeta/id1476431314",
  },
]

export {
  CasesTotensData,
  CaseAppsData,
};