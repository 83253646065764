import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet, ShowCase, SVG } from "../../../components";
import _ from "styled-components";

export default ({ caseData }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Desenvolvimento mobile Laus"
      description="Desenvolvimento de aplicativo mobile nas plataformas iOS e Android, para revendedores, exercendo também a função de catálogo de produtos, além desenvolvimento de painel administrativo web para gestão dos revendedores, pedidos, produtos e parametrizações em geral."
    />
    <div className="main">
      <div className="section case-section white-bg">
        <div className="container cover-page-alt">
          <div className="section title-section">
            <ol className="breadcrumb white-bg">
              <li className="breadcrumb-item">
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cases/" title="Cases">
                  Cases
                </Link>
              </li>
              <li className="breadcrumb-item active">LausGirl & Co</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="section app-showcase">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img className="app-logo" src={caseData.image} />
              <div>
                <h1>Aplicativo Laus</h1>
                <p>
                  A plataforma da LausGirl & Co, é composta por aplicativo
                  mobile na versão android (Google) e iOS (Apple) e Painel
                  administrativo que permite a gestão dos produtos, pedidos e
                  revendedores.
                </p>
              </div>
              <div>
                <a target="_blank" href={caseData.playStore}>
                  <MobileLogo
                    src={require("../../../assets/cases/play_store.png")}
                  />
                </a>
                <a href={caseData.appStore}>
                  <MobileLogo
                    src={require("../../../assets/cases/app_store.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="context-image d-none d-md-block"
        src={require("../../../assets/cases/laus-girl.png")}
      />

      <div className="section white-bg app-objective">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <h3>Objetivo</h3>
                <p>
                  Com um aplicativo mobile desenvolvido exclusivamente para os
                  revendedores colocarem os seus pedidos, o aplicativo absorve
                  também as funcionalidade de catálogo de produtos. Com o
                  aplicativo mobile, a Laus atende todo o território nacional,
                  fazendo com que homens em mulheres tenham a seus pés produtos
                  Laus & Co.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                A venda pelo aplicativo mobile representa o mais forte canal de
                venda, contemplando mais de 800 modelos a pronta entrega e mais
                de 700 revendedores no Brasil. A solução visa gerenciar os
                aspectos mais sensíveis no serviço prestado ao cliente, como o
                tempo de espera; e, adaptar os diferentes tipos de atendimento
                que a loja oferece.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg">
        <div className="container">
          <ShowCase data={caseData} />
        </div>
      </div>

      <div className="explanation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="explanation-title">
                <div className="text-center app-call-to-action-title">
                  Tem interesse em tirar sua ideia do papel?
                </div>
                <span className="app-call-to-action-subtitle">
                  Nossa equipe de desenvolvimento possui amplo conhecimento de
                  negócio sendo especializada no desenvolvimento de aplicativos
                  mobile, projetados de acordo com a necessidade do negócio.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 social-content-parallax alt-2">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>1</span>
                    <p className="app-call-to-action-card-text">
                      Compreendemos as necessidades do cliente e através da
                      consultoria, ajudamos a formar a visão e os requisitos do
                      projeto.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>2</span>
                    <p className="app-call-to-action-card-text">
                      Estruturamos a arquitetura do projeto, definindo as
                      tecnologias e recursos necessários para o desenvolvimento
                      da solução.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>3</span>
                    <p className="app-call-to-action-card-text">
                      Efetuamos a validação detalhada do projeto e juntamente
                      com o aval do cliente, liberamos o projeto em produção.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="app-call-to-action-text ">
              As ideias são perfeitamente integradas ao nosso processo de design
              e desenvolvimento para produzir aplicativos modernos, elegantes e
              de alta performance.
            </p>
          </div>
        </div>
      </div>

      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e
              comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

const MobileLogo = _.img`
  width: 130px;
  margin: 60px 12px 20px 0;
`;
