import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Desenvolvimento de Aplicativos"
      description="Desenvolvimento de aplicativos mobile para sistema operacional Android e iOS. Todos os projetos são especiais e cada etapa do desenvolvimento contam com interface e tecnologia moderna, sempre alinhada com a estratégia de negócio do cliente."
      canonical="https://itstecnologia.digital/desenvolvimento-aplicativos/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height apps-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Desenvolvimento de Aplicativos</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" title="Home">Home</Link></li>
                <li className="breadcrumb-item active">Desenvolvimento aplicativos</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section apps-page white-bg">
        <div className="container">
          <h2 className="toten-subtitle">
            Desenvolvemos aplicativos de alta performance para o seu negócio.
        </h2>
          <div className="row">
            <div className="col-12 col-md-6 align-self-center d-none d-sm-block">
              <div className="mock-wrapper">
                <img title="Smartphones com os aplicativos Idelo e Ninky em suas telas" className="img-fluid" src={require("../../assets/desenvolvimento-mobile-aplicativo-idelo-e-ninky.png")} alt="Desenvolvimento de aplicativo mobile dos projetos Idelo e Ninky." />
              </div>
            </div>
            <div className="col-12 col-md-6 p-left-36">
              <p>
                Gostamos de desafios e de tecnologia, por isto acompanhamos as tendências e utilizamos o que há de
                mais moderno e consolidado em ferramentas de tecnologia, no
                    <span> desenvolvimento de aplicativos mobile</span> iOS (Apple) e Android (Google),
aplicativos embarcados em totens, além de sistemas web convencionais.
                </p>
              <p clas="no-margin-bottom">
                Apenas a tecnologia, entretanto não é suficiente para entregar bons produtos, o <span> conhecimento
                        de negócio </span> e a <span> experiência </span> de mercado em soluções, negócios e produtos,
convergem e impactam diretamente na entrega de um produto de qualidade, útil e de fácil utilização.
                </p>
              <div className="os-logo-wrapper">
                <div className="row">
                  <div className="col-12">
                    <SVG
                      src={require("../../assets/svg/apple_logo.svg")}
                    />
                    <SVG
                      src={require("../../assets/svg/android_logo.svg")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row v-padding-70">
            <div className="col-12">
              <h2 className="subtitle">Os projetos de desenvolvimento mobile podem ser segmentados em:</h2>
              <ul className="app-list-structure">
                <li>
                  Aplicativos para <span> empreendedores</span>, partindo desde o painel administrativo
                  permitindo o gerenciamento do sistema, páginas web de divulgação e aplicativos mobile
                  nas plataformas Android e iOS.
                    </li>
                <li>
                  Aplicativos mobile para empresas nas plataformas <span>Android</span> e <span>iOS</span>,
                  podendo integrar com sistemas existentes.
                    </li>
                <li>
                  Aplicativos embarcados em <span>totens digitais</span>.
                    </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section apps-page-alt">
        <div className="container">
          <div className="row justify-content-around">

            <div className="proccess-card">
              <div className="header-icon">
                <img title="Levantamento de Requisitos" src={require("../../assets/icone-levantamento-de-requisitos.png")} alt="Desenvolvimento de aplicativo mobile etapa levantamento de requisitos." />
              </div>
              <div className="header-title">
                Levantamento de Requisitos
                </div>
              <hr className="separator-line" />
              <div className="description">
                Através dos questionamentos em conjunto com o cliente, determinamos os objetivos e restrições do
                projeto.
                </div>
            </div>
            <div className="proccess-card">
              <div className="header-icon">
                <img title="Design de Interface" src={require("../../assets/icone-design-de-interface.png")} alt="Desenvolvimento de aplicativo mobile etapa design de interfaces." />
              </div>
              <div className="header-title">
                Design de Interface
                </div>
              <hr className="separator-line" />
              <div className="description">
                Com base nos requisitos especificados, o design considera a experiência do usuário com a interface e
                funcionalidades do aplicativo.
                </div>
            </div>
            <div className="proccess-card">
              <div className="header-icon">
                <img title="Desenvolvimento Mobile" src={require("../../assets/icone-desenvolvimento-mobile.png")} alt="Desenvolvimento de aplicativo mobile etapa programação." />
              </div>
              <div className="header-title">
                Desenvolvimento Mobile
                </div>
              <hr className="separator-line" />
              <div className="description">
                Os requisitos do projeto se tornam linhas de código, visando a entrega de um produto estruturado e
                de
                alta performance.
                </div>
            </div>
            <div className="proccess-card">
              <div className="header-icon">
                <img title="Testes Funcionais e de Usabilidade" src={require("../../assets/icone-testes-e-usabilidade.png")} alt="Desenvolvimento de aplicativo mobile etapa testes e usabilidade." />
              </div>
              <div className="header-title">
                Testes Funcionais e Usabilidade
                </div>
              <hr className="separator-line" />
              <div className="description">
                Fundamental para validação das funcionalidades e também a usabilidade do projeto, um grupo de
                testadores realiza os testes antes de realizar o lançamento do aplicativo.
                </div>
            </div>
            <div className="proccess-card">
              <div className="header-icon">
                <img title="Lançamento do Aplicativo" src={require("../../assets/lancamento-do-aplicativo.png")} alt="Desenvolvimento de aplicativo mobile etapa lançamento do aplicativo nas lojas." />
              </div>
              <div className="header-title">
                Lançamento do Aplicativo
                </div>
              <hr className="separator-line" />
              <div className="description">
                Após executarmos a fase de testes, o projeto tem sua versão lançada no mercado, nós ficamos atentos
                as etapas de publicação nas lojas.
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section apps-page half-light-grey">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="team-section">
                Para que possamos combinar tecnologia e negócios, formamos uma equipe com visão comercial,
                estratégica, design, técnica e de negócios.
                    <p>
                  Compartilhamos com os clientes a experiência adquirida, absorvendo as dificuldades e objetivos
                  do projeto, assim, transformamos a sua necessidade em um aplicativo mobile eficiente, rápido,
                  simples e intuitivo.
                    </p>
              </div>
            </div>
            <div className="d-none d-md-inline-block col-md-7">
              <img title="Profissionais Trabalhando" className="img-fluid alt" src={require("../../assets/imagem-de-profissionais-trabalhando.jpg")} alt="Profissionais trabalhando com papéis e computadores sobre a mesa." />
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment >
);
