import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet, ShowCase, SVG } from "../../../components";

export default ({ caseData }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totem digital Votorantim"
      description="Disponibilizamos totem tablet e desenvolvemos aplicativo embarcado em totem, que possibilita o apontamento de ordem de serviço dos colaboradores que estão em campo e/ou alocados internamente na empresa."
    />
    <div className="main">
      <div className="section case-section white-bg">
        <div className="container cover-page-alt">
          <div className="section title-section">
            <ol className="breadcrumb white-bg">
              <li className="breadcrumb-item">
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cases/" title="Cases">
                  Cases
                </Link>
              </li>
              <li className="breadcrumb-item active">Votorantim</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="section app-showcase">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img className="app-logo" src={caseData.image} />
              <div>
                <h1>Totem Digital Votorantim</h1>
                <p>
                  Algumas empresas possuem demandas específicas para uso interno
                  e necessitam de soluções inteligentes para automatizar seus
                  processos. Este é o case da Votorantim Cimentos.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          className="context-kiosk d-none d-md-block"
          src={require("../../../assets/cases/votorantim.jpg")}
        />
      </div>

      <div className="section white-bg app-objective">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h3>Objetivo</h3>
                <p>
                  Desenvolvemos um aplicativo mobile que possibilita o apontamento
                  de ordem de serviço dos colaboradores que estão em campo.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                O objetivo da empresa é promover agilidade e qualidade no
                recebimento dos dados, além da otimização do tempo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg">
        <div className="container">
          <ShowCase data={caseData} />
        </div>
      </div>

      <div className="explanation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="explanation-title">
                <div className="text-center app-call-to-action-title">
                  Automatize as soluções do seu negócio!
                </div>
                <span className="app-call-to-action-subtitle">
                  Desenvolvemos projetos de totens digitais de autoatendimento
                  personalizados de acordo com a necessidade de cada projeto e
                  também desenvolvemos aplicativos embarcados conciliando
                  agilidade, praticidade e economia de recursos.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 social-content-parallax alt-2">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>1</span>
                    <p className="app-call-to-action-card-text">
                      Compreendemos as necessidades do cliente e
                      disponibilizamos equipamentos adicionais para serem
                      acoplados ao projeto, como impressora, leitor biométrico,
                      base/berço para pin-pad entre outros.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>2</span>
                    <p className="app-call-to-action-card-text">
                      Desenvolvemos aplicativos embarcados nos totens,
                      estruturamos a arquitetura do projeto e definimos os
                      recursos necessários para o desenvolvimento da solução.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>3</span>
                    <p className="app-call-to-action-card-text">
                      Efetuamos a validação detalhada do projeto e juntamente
                      com o aval do cliente, liberamos o projeto.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="app-call-to-action-text ">
              Fabricamos totem digital de autoatendimento e desenvolvemos
              aplicativos embarcados que conectam sua empresa ao seus clientes,
              de acordo com a necessidade de cada projeto.
            </p>
          </div>
        </div>
      </div>
      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e
              comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
