import React, { Component } from "react";
import { CasesTotensVotorantimPage } from "../pages";

class CasesTotensVotorantimController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Votorantim",
        image: require("../assets/supporters/votorantim_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo, Totem Tablet, Desenvolvimento de aplicativo mobile.",
        segment: "Eventos",
        solution: "Totem de autoatendimento com desenvolvimento de aplicativo mobile de apontamento de ordem de serviço."
      }
    };
  }

  render = () => <CasesTotensVotorantimPage {...this.state} {...this.props} />;
}

export default CasesTotensVotorantimController;
