import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Consultoria em Tecnologia"
      description="A consultoria em tecnologia tem foco em negócios de tecnologia, contemplando totem digital e desenvolvimento de aplicativos. O processo de análise dos requisitos gera um documento técnico do projeto. Traga seu projeto para a iTS Enterprise Innovation."
      canonical="https://itstecnologia.digital/consultoria-em-tecnologia/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height consulting-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Consultoria em tecnologia</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" title="Home">Home</Link></li>
                <li className="breadcrumb-item active">Consultoria em tecnologia</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section consulting-page white-bg">
        <div className="container">
          <h2 className="toten-subtitle">Realizamos consultoria em tecnologia e estruturação do projeto, contemplando Hardware
              e Software com foco no negócio do cliente.
        </h2>

          <div className="main-timeline">
            <div className="timeline">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <h3 className="title">Consultoria</h3>
                <p className="description">
                  Desenvolver um produto com viabilidade técnica, de negócio, que seja seguro e com performance é
                  um dos grandes desafios atuais do empreendedor.
                    </p>
              </div>
              <div className="consulting-img-wrapper">
                <img title="Consultoria" className="right" src={require("../../assets/icone-consultoria.png")} alt="Ícone de consultoria para desenvolver produto." />
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-icon"></div>
              <div className="timeline-content right">
                <h3 className="title">Orientação</h3>
                <p className="description">
                  O papel do consultor ou mentor, é orientar o cliente e ajudá-lo a <span>tomar decisões</span>,
                  considerando a experiência e abrangência profissional.
                    </p>
                <div className="consulting-img-wrapper">
                  <img title="Orientação" className="left" src={require("../../assets/icone-orientacao.png")} alt="Ícone de orientação do consultor na consultoria em tecnologia." />
                </div>
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <h3 className="title">Desenvolvimento</h3>
                <p className="description">
                  É comum o cliente a partir de uma ideia, entender que o próximo passo é o desenvolvimento
                  tecnológico da solução/produto, entretanto se o projeto do negócio não estiver bem
                  especificado o impactos direto será um projeto com não conformidades, prazos longos e
                  consequentemente altos custos.
                    </p>
                <div className="consulting-img-wrapper">
                  <img title="Desenvolvimento" className="right" src={require("../../assets/icone-desenvolvimento.png")} alt="Ícone de desenvolvimento tecnológico na solução do produto." />
                </div>
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-icon"></div>
              <div className="timeline-content right">
                <h3 className="title">Conscientização</h3>
                <p className="description">
                  As decisões tomadas no desenvolvimento de um negócio, terão consequências a longo prazo, logo é
                  necessário ter plena consciência de onde devem ficar concentrados os esforços, o tempo e custos
                  consumidos.
                    </p>
                <div className="consulting-img-wrapper">
                  <img title="Conscientização" className="left" src={require("../../assets/icone-conscientizacao.png")} alt="Ícone de conscientização para tomadas de decisão." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section social-content-parallax alt no-padding no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">
                  Como fazemos
                    </h2>
              </div>
              <div className="col-12 align-self-center">
                <div className="social-card alt">
                  <span>1</span>
                  <p>
                    <strong> Análise/Entendimento</strong> <br />
                    Nosso objetivo é analisar a necessidade do cliente, provocando questionamentos,
                    compartilhando informações relevantes de
                    mercado, financeiras, legais e tecnológicas, sempre contribuindo com perspectivas diferentes
                    com o objetivo de validação
                    do negócio.
                        </p>
                </div>
              </div>
              <div className="col-12 align-self-center">
                <div className="social-card alt">
                  <span>2</span>
                  <p>
                    <strong> Especificação dos Requisitos </strong> <br />
                    A especificação dos requisitos dos projetos, delimita ou expande o projeto considerando a
                    sua aderência, custos e
                    prazos.
                        </p>
                </div>
              </div>
              <div className="col-12 align-self-center">
                <div className="social-card alt">
                  <span>3</span>
                  <p>
                    <strong>Documentação</strong> <br />
                    A documentação é a terceira etapa do projeto, que especifica detalhadamente as necessidades
                    para o desenvolvimento do
                    projeto, sendo validado juntamente com o cliente.
                    O documento do projeto gerado será a base do desenvolvimento técnico do projeto, garantindo
                    os alinhamentos das
                    expectativas do que deve ser entregue/recebido.
                        </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section apps-page very-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="team-section-alt text-right">
                O nosso serviço de consultoria em tecnologia envolve uma equipe de especialistas que utilizam sua
                expertise na área para ajudar os clientes a atingirem seus objetivos.
                <p>
                  Atuamos especialmente no apoio a estruturação de escopo de projeto técnico e/ou modelo de
                  negócios, envolvendo a empresa como um todo.
                </p>
              </div>
            </div>
            <div className="d-none  d-md-block col-12 col-md-6 align-self-center">
              <img title="Planejamento" width="80%" src={require("../../assets/imagem-de-profissionais-realizando-planejamento.jpg")} className="m-width-480" alt="Ícone representando equipe de consultores realizando o planejamento na consultoria em tecnologia." />
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment>
);
