import React, { Component } from "react";
import { CasesTotensCondorPage } from "../pages";

class CasesTotensCondorController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Condor",
        image: require("../assets/supporters/condor_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo, Totem Tablet, Desenvolvimento de aplicativo mobile, e desenvolvimento web",
        segment: "Comércio Varejo",
        solution: "Totem de autoatendimento"
      }
    };
  }

  render = () => <CasesTotensCondorPage {...this.state} {...this.props} />;
}

export default CasesTotensCondorController;
