import React, { Component } from "react";
import { CasesTotensIdeloPage } from "../pages";

class CasesTotensIdeloController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Idelo",
        playStore: "https://play.google.com/store/apps/details?id=br.com.squarebits.idelo",
        image: require("../assets/cases/logo-idelo-full.png"),
        category: "Desenvolvimento de aplicativos mobile, Desenvolvimento mobile, Desenvolvimento de app",
        segment: "Empreendedores, Investidores, Startup",
        solution: "Desenvolvimento de aplicativos mobile iOS e Android"
      }
    };
  }

  render = () => <CasesTotensIdeloPage {...this.state} {...this.props} />;
}

export default CasesTotensIdeloController;
