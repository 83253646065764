import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Sobre Nós"
      description="A iTS Enterprise Innovation é uma empresa especializada no desenvolvimento de soluções digitais, contemplando totem digital, desenvolvimento de aplicativos mobile, aplicativos para totem e consultoria em negócios de tecnologia."
      canonical="https://itstecnologia.digital/sobre-nos/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height about-us-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>iTS Tecnologia</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Sobre Nós</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section about-page white-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="toten-subtitle toten-subtitle-color">
                Vislumbramos grandes oportunidades!
              </h2>
            </div>
            <div className="d-none d-md-block col-md-3 custom-margin-1">
              <div className="medium-icon-wrapper">
                <SVG
                  src={require("../../assets/svg/kiosk.svg")}
                  className="medium-icon"
                />
              </div>
            </div>
            <div className="col-12 col-md-9 custom-margin-1">
              <p>
                A iTS Enterprise Innovation é especializada em desenvolver
                plataformas de soluções de negócios, contemplando Totens
                Digitais de autoatendimento, Desenvolvimento de aplicativos e
                Mentoria/consultoria em negócios de tecnologia.
              </p>
              <p>
                Unimos duas expertises tecnológicas, a de fabricação de totem
                digital e o desenvolvimento de aplicativos mobile e aplicativos
                embarcados. Somamos o conhecimento de negócios e tecnologia dos
                profissionais das duas empresas, e com isto, entregamos soluções
                digitais completas.
              </p>
              <p>
                Nossas soluções contemplam a Fabricação de totens digitais,
                desenvolvimento de aplicativos mobile, projetos de software
                embarcados, projetos web tradicionais e consultoria em projetos
                de tecnologia,
              </p>
            </div>
          </div>
        </div>

        <div className="section social-content-parallax no-box-addition">
          <div className="mask">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-center">
                    Expandimos o desenvolvimento de nossas habilidades baseada
                    na necessidade de nossos clientes - trabalhamos muito nisso,
                    do início ao fim do projeto!
                  </h2>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card">
                    <span>1</span>
                    <p>NOS APROFUNDAMOS NO OBJETIVO DE NEGÓCIO DO CLIENTE</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card">
                    <span>2</span>
                    <p>
                      UTILIZAMOS FERRAMENTAS AVANÇADAS, QUE NOS TORNAM FLEXÍVEIS
                      E ÁGEIS
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card">
                    <span>3</span>
                    <p>ENTREGAMOS COM COMPROMETIMENTO E CONFIABILIDADE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row v-padding-70">
              <div className="col-12">
                <div className="social-responsible">
                  <h2>Responsabilidade Social</h2>
                  <p>
                    A iTS, representada por seus sócios e colaboradores, entende
                    que sua atuação deve considerar, de forma integrada, a
                    contribuição para o desenvolvimento econômico da sua
                    carteira de clientes e consequentemente do país, bem como o
                    investimento no desenvolvimento social, cultural e
                    ambiental, de forma a contribuir efetivamente para a
                    construção de uma sociedade mais justa e equilibrada.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section social-content-show">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="social-item">
                  <h3>Fundação Educacional Meninos de Rua Profeta Elias</h3>
                  <p>
                    Foi estabelecida uma parceria com a Fundação Educacional
                    Meninos de Rua Profeta Elias, de Quatro Pinheiros,
                    Mandirituba – PR, visando contribuir prioritariamente no
                    suporte a atividades voltadas ao esporte, considerando a
                    viabilização de aulas de artes marciais para os meninos de 4
                    a 18 anos, bem como o apoio na viabilização de material
                    esportivo necessário para as aulas.
                  </p>
                  <h5>Sobre a fundação</h5>
                  <p>
                    A Fundação Educacional Profeta Elias, mais conhecida como
                    Chácara Meninos de 4 Pinheiros, é uma organização não
                    governamental, sem fins lucrativos, com a finalidade de dar
                    assistência e educação integral a crianças e adolescentes de
                    classes menos favorecidas, em situação de vulnerabilidade
                    social, principalmente as que vivem nas ruas de Curitiba e
                    Região Metropolitana.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="social-item-show">
                  <h4>Formação Profissional</h4>
                  <ul className="check-list-structure">
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Incentivo, orientação e preparação aos meninos para a
                      iniciação profissional
                    </li>
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Preparação dos meninos para atuação como técnicos em
                      informática
                    </li>
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Apoio em questões relacionadas à subsistência e transporte
                      para realização dos treinamentos
                    </li>
                  </ul>
                  <h4>Suporte a atividades esportivas</h4>
                  <ul className="check-list-structure">
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Viabilização de aulas de artes marciais para os meninos de
                      4 a 18 anos, realizadas na chácara
                    </li>
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Apoio na viabilização de material esportivo necessário
                      para as aulas acima
                    </li>
                  </ul>
                  <div className="row m-top-30">
                    <img
                      title="Treino de jiu-jistu entre duas pesoas, na fundação"
                      height="100px"
                      src={require("../../assets/fundacao/atividade-esportiva-jiu-jitsu-01.jpg")}
                      alt="Duas pessoas treinando jiu-jitsu na Fundação Educacional Meninos de Rua Profeta Elias."
                      className="w-auto"
                    />
                    <img
                      title="Treino de jiu-jistu em grupo, na fundação"
                      height="100px"
                      src={require("../../assets/fundacao/atividade-esportiva-jiu-jitsu-02.jpg")}
                      alt="Grupo de pessoas treinando jiu-jitsu na Fundação Educacional Meninos de Rua Profeta Elias."
                      className="w-auto"
                    />
                    <img
                      title="Treino de jiu-jistu com instrutor, na fundação"
                      height="100px"
                      src={require("../../assets/fundacao/atividade-esportiva-jiu-jitsu-03.jpg")}
                      alt="Grupo crianças e instrutor do time de jiu-jitsu na Fundação Educacional Meninos de Rua Profeta Elias."
                      className="w-auto"
                    />
                    <img
                      title="Treino de jiu-jistu na fundação, com crianças"
                      height="100px"
                      src={require("../../assets/fundacao/atividade-esportiva-jiu-jitsu-04.jpg")}
                      alt="Instrutor e crianças do time de jiu-jitsu na Fundação Educacional Meninos de Rua Profeta Elias."
                      className="w-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section social-content-show-alt">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="social-item-alt">
                  <h3 className="dark-blue-color">Complexo Pequeno Príncipe</h3>
                  <p>
                    Foi estabelecida uma parceria com o Complexo Pequeno
                    Príncipe, visando contribuir prioritariamente nas seguintes
                    áreas:
                  </p>
                  <ul className="check-list-structure blue-svg">
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Apoio financeiro mensal para subsídio das atividades
                    </li>
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Participação no Clube de Benefícios ou do Projeto Seleção
                      Gols Pela Vida
                    </li>
                    <li>
                      <SVG
                        className="check-icon"
                        src={require("../../assets/svg/check.svg")}
                      />
                      Descontos em serviços para empresas parceiras do Complexo
                      Pequeno Príncipe
                    </li>
                  </ul>
                  <br />
                  <h4 className="dark-blue-color">
                    Sobre o Complexo Pequeno Príncipe
                  </h4>
                  <p>
                    O Complexo Pequeno Príncipe promove de forma integrada
                    saúde, educação, arte, cultura e mobilização social para um
                    novo modo de ser e viver. Inspirado na tríade da assistência
                    em saúde, do ensino e da pesquisa, alia conhecimento
                    científico e novas tecnologias com a luta pela causa da
                    saúde infanto-juvenil e a formação de novos profissionais.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="social-item-show-alt">
                  <h4>Fazem parte do Complexo</h4>
                  <ul className="check-list-structure-alt">
                    <li>Hospital Pequeno Príncipe</li>
                    <li>Instituto de Pesquisa Pelé Pequeno Príncipe</li>
                    <li>Faculdades Pequeno Príncipe</li>
                  </ul>

                  <h4>Conheça o Complexo Pequeno Príncipe</h4>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title="youtube-complex"
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/I0NXMcCGnOU"
                      frameBorder="0"
                      allowFullScreen=""
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section partners-section no-padding-bottom">
          <div className="container">
            <div className="row gutters">
              <div className="col-12 col-md-8 quote-about">
                <p className="dark-grey-color">
                  <em>
                    "O dever é uma coisa muito pessoal, decorre da necessidade
                    de se entrar em ação, e não da necessidade de insistir com
                    os outros para que façam qualquer coisa."
                  </em>
                  <br />- Madre Teresa de Calcutá
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="row gutters">
                  <div className="col-6 text-center">
                    <a
                      href="http://pequenoprincipe.org.br/sobre-o-complexo/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        title="Logo da fundação Pequeno Príncipe"
                        src={require("../../assets/logo-pequeno-principe.png")}
                        alt="Fundação Pequeno Príncipe"
                        className="w-auto h-110"
                      />
                    </a>
                  </div>
                  <div className="col-6 text-center">
                    <a
                      href="http://www.4pinheiros.org.br/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        title="Logo Meninos de 4 Pinheiros"
                        src={require("../../assets/logo-4-pinheiros.png")}
                        alt="Meninos de 4 Pinheiros"
                        className="w-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
