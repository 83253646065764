import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totem de Autoatendimento"
      description="Conheça os Totens de Autoatendimento fabricados pela iTS Enterprise Innovation. São equipamentos robustos, seguros e resistentes. Por serem altamente versáteis, possibilitam o acoplamento de opcionais. São ideais para lojas, supermercados, hospitais e também outros ambientes com atendimento."
      canonical="https://itstecnologia.digital/totens-digitais/totens-autoatendimento/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height totens-autodesk-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1 className="title-section-text">Totens de Autoatendimento</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/" title="Totens Digitais">
                    Totens Digitais
                  </Link>
                </li>
                <li className="breadcrumb-item active">Totens de Autoatendimento</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg about-content no-bg-img p-bottom-70">
        <div className="container">
          <h2>Totem Interativo PRO</h2>
          <div className="row gutters">
            <div className="col-12 toten-explanation">
              <p>
                Os totens interativos PRO são frequentemente <span>utilizados</span> para aplicações de <span>
                  soluções em lojas, supermercados, hospitais</span>, ou em qualquer ambiente que demande
                    <span> autoatendimento</span> inclusive <span>pagamentos</span>, possibilitando a absorção de
    atividades em que o usuário possa agilizar seu próprio atendimento.
                </p>
              <p>
                Sua <span>aplicação</span> é bastante diversificada já que este tipo de totem conta com
                    <span> diversos opcionais</span> que vão desde a possibilidade de <span> acoplar
        impressoras, equipamento de pagamento pin-pad, leitor de código de barras, som,
                        webcam, leitor biométrico </span> entre outros.
                </p>
              <p>
                Decorrente da forma e dos locais sujeitos a movimentação de pessoas e mercadorias em que o totem PRO
                    é utilizado, alguns cuidados merecem atenção especial no que se refere a <span> resistência,
                    segurança e desempenho. </span>Os totens PRO possuem tela com<span> proteção anti-impacto, pés
                    fixos ou rodízios de silicone, chave liga/desliga </span>para ser removida, além de alto
                    desempenho com <span> armazenamento SSD</span>.
                </p>
            </div>
            <div className="col-12">
              <h2 className="title-alt">Destaques</h2>
              <ul className="check-list-structure feat">
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Resistentes;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Seguras;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Alto Desempenho;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Aplicação Diversificada;</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="section gallery-content">
            <div className="container">
              <h2>Galeria</h2>
              <div className="gallery-row">
                <div className="gallery-col">
                  <img title="Totem de Autoatendimento com pinpad" src={require("../../assets/galleries/autodesk/totem-de-autoatendimento-com-pin-pad.jpg")} alt="Totem de autoatendimento com equipamento pinpad para pagamento." />
                  <img title="Totem de Autoatendimento com checkin facial" src={require("../../assets/galleries/autodesk/totem-de-autoatendimento-parana-clinicas-02.jpg")} alt="Totem de autoatendimento com checkin facial alocado na Paraná Clínicas." />
                </div>
                <div className="gallery-col">
                  <img title="Totem de Autoatendimento com checkin facial, na fábrica" src={require("../../assets/galleries/autodesk/totem-de-autoatendimento-parana-clinicas-01.jpg")} alt="Totem de autoatendimento com checkin facial na fábrica da iTS para o cliente Paraná Clínicas." />
                  <img title="Totem de Autoatendimento na loja Havan" src={require("../../assets/galleries/autodesk/totem-de-autoatendimento-havan.jpg")} alt="Totem de autoatendimento alocado na loja Havan para Gestão de Filas." />
                </div>
                <div className="gallery-col">
                  <img title="Totem de Autoatendimento na loja Condor" src={require("../../assets/galleries/autodesk/totem-autoatendimento-clube-condor-01.jpg")} alt="Totem de autoatendimento alocado no Condor para o Clube Condor." />
                </div>
                <div className="gallery-col">
                  <img title="Totem de Autoatendimento e Totem Tablet, plotados com iTS Tecnologia" src={require("../../assets/galleries/autodesk/equipamentos-totem-de-autoatendimento-totem-tablet.jpg")} alt="Totem de autoatendimento e totem tablet com plotagem da iTS Tecnologia." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section dark-bg padding-70 resources-content no-bg-img">
        <div className="container">
          <div className="row">
            <h2>Características</h2>
          </div>
          <div className="row gutters">
            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/anti-impacto.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Tela com Proteção Anti-Impacto</h3>
                <p>Proteção de tela para suportar impactos fortes, aumentando o tempo de vida do equipamento e
                        reduzindo manutenções.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/alto-desempenho.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Alto Desempenho</h3>
                <p>Equipamento de alto desempenho, contando com armazenamento SSD para maior agilidade e comodidade
                        no atendimento.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/bivolt.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Bivolt Automático</h3>
                <p>Pensando na variedade de ambientes, os equipamentos possuem fonte bivolt automática, se adaptando
                        a redes 110v ou 220v sem necessidade de ajuste manual.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/chave-liga-desliga.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Chave Liga/Desliga</h3>
                <p>Utilização de chave para ligar/desligar o equipamento, que pode ser removida após o uso para
                        limitar o uso somente por pessoas autorizadas.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/suporte.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Opções de Suporte</h3>
                <p>Possiblidade de utilização de pés fixos ou rodízios de silicone com trava de acordo com a
                        necessidade de mobilidade do equipamento.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/opcionais.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Opcionais</h3>
                <p>Diversa gama de opcionais para complementar seu equipamento de autoatendimento.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Entre em contato, traga seu projeto para a iTS!</h3>
              </div>
              <div className="col-12 col-md-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg padding-70 resources-content">
        <div className="container">
          <h2 className="dark-red-color">Especificações Técnicas</h2>
          <table className="table-striped table">
            <thead>
              <tr>
                <th>Itens</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gabinete</td>
                <td>Metal, disponível nas cores Preto ou Branco</td>
              </tr>
              <tr>
                <td>Proteções do gabinete</td>
                <td>Maresia, Estática e Corrosão</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>18,5 polegadas com proteção anti impacto</td>
              </tr>
              <tr>
                <td>Touch Screen</td>
                <td>1 toque <br /> Multitoque <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Processador</td>
                <td>Dual Core ou superior</td>
              </tr>
              <tr>
                <td>Memória</td>
                <td>4GB</td>
              </tr>
              <tr>
                <td>Armazenamento</td>
                <td>SSD 1200GB</td>
              </tr>
              <tr>
                <td>Voltagem</td>
                <td>Bivolt automático (110v / 220v)</td>
              </tr>
              <tr>
                <td>Ethernet</td>
                <td>Ethernet Gigabit - 1.000Mbps <br /> WiFi – 200Mbps
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Câmera</td>
                <td>Full HD <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Som</td>
                <td>Saída de áudio lateral <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Impressora</td>
                <td>Térmica 80 mm com corte automático <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Suporte para PinPad</td>
                <td>Gertec PPC 910, Ingênico IPP 320 ou IPP 350 <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Sistema Operacional</td>
                <td>Linux Ubuntu <br /> Microsoft Windows <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Features Específicos</td>
                <td>Leitor Cód. de Barras <small>(OPCIONAL)</small> <br /> Leitor Tarja
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Pés</td>
                <td>Pés fixos com regulagem de altura <br /> Rodízio em silicone com trava
                        <small>(OPCIONAL)</small></td>
              </tr>
              <tr>
                <td>Peso Aproximado</td>
                <td>65kg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Tablet</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de baixo custo e pequenos formatos, utilizados em áreas
                        com limitação de espaço físico ou para solução de baixa complexidade.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/mesas-touch-screen/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/mesa-digital.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Mesas Touch Screen</h3>
                      <p className="products-recall-description">
                        Mesa Touch Screen Interativas em grandes formatos possibilitando ampla visualização
                        das informações apresentadas. Muito utilizada para apresentação de conteúdo.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-carregadores/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-carregador.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Carregadores</h3>
                      <p className="products-recall-description">
                        Totens carregadores de bateria para dispositivos móveis, com associação de mída
                        indoor.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment >
);
