import React from "react";
import _ from "styled-components";

export default ({ data }) => {
  if (data)
    return (
      <>
        <AdditionalInfoWrapper>
          <AdditionalInfo>
            Categoria:
            <br />
            <span> {data.category}</span>
          </AdditionalInfo>
          <AdditionalInfo>
            Segmento:
            <br />
            <span> {data.segment}</span>
          </AdditionalInfo>
          <AdditionalInfo>
            Solução:
            <br />
            <h2> {data.solution}</h2>
          </AdditionalInfo>
        </AdditionalInfoWrapper>
      </>
    );

  return null;
}

const AdditionalInfoWrapper = _.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (max-width: 576px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const AdditionalInfo = _.div`
  width: 30%;
  font-size: 0.8em;
  color: #666;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 3.33%

  span, h2 {
    font-size: 1.44em;
    text-transform: none;
    color: #333;
    font-weight: 400;
    margin-top: 8px;
    display:inline-block;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 20px;
  }
`;
