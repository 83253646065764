import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet, ShowCase, SVG } from "../../../components";

export default ({ caseData }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totem digital Leroy Merlin."
      description="Disponibilizamos totem de autoatendimento e desenvolvemos o aplicativo embarcado em totem digital  para a Leroy Merlin unidade de Curitiba-PR. A tecnologia aplicada permite a apresentação da promoções e mapa da localização do produto em promoção."
    />
    <div className="main">
      <div className="section case-section white-bg">
        <div className="container cover-page-alt">
          <div className="section title-section">
            <ol className="breadcrumb white-bg">
              <li className="breadcrumb-item">
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cases/" title="Cases">
                  Cases
                </Link>
              </li>
              <li className="breadcrumb-item active">Leroy Merlin</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="section app-showcase">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <img className="app-logo" src={caseData.image} />
              <div>
                <h1>Totem Digital Leroy Merlin</h1>
                <p>
                  A Leroy Merlin é uma empresa especializada em
                  Construção, Acabamento, Bricolagem, Decoração e Jardinagem. No
                  Brasil, possui 41 Lojas distribuídas em onze estados
                  brasileiros e o Distrito Federal e uma Loja Virtual que atende
                  todos os estados do país. Disponibilizamos totem de
                  autoatendimento e também desenvolvemos o aplicativo do totem
                  de mídia indoor de uma das lojas físicas da Leroy Merlin em
                  Curitiba-PR, o totem de autoatendimento tem a principal
                  finalidade de comunicar aos clientes da loja as promoções do
                  dia.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          className="context-kiosk d-none d-md-block"
          src={require("../../../assets/cases/leroy.jpg")}
        />
      </div>

      <div className="section white-bg app-objective">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h3>Objetivo</h3>
                <p>
                  A tecnologia aplicada no aplicativo embarcado, permite que as
                  promoções sejam cadastradas através de um sistema de
                  gerenciamento e automaticamente exibidas no totem.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                O cadastro das promoções por departamento é um dos diferenciais
                e no totem, é apresentado um mapa da loja dividido por
                departamentos, ao clicar no departamento desejado no mapa todas
                as promoções relacionadas a ele são exibidas.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg">
        <div className="container">
          <ShowCase data={caseData} />
        </div>
      </div>

      <div className="explanation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="explanation-title">
                <div className="text-center app-call-to-action-title">
                  Automatize as soluções do seu negócio!
                </div>
                <span className="app-call-to-action-subtitle">
                  Desenvolvemos projetos de totens digitais de autoatendimento
                  personalizados de acordo com a necessidade de cada projeto e
                  também desenvolvemos aplicativos embarcados conciliando
                  agilidade, praticidade e economia de recursos.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 social-content-parallax alt-2">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>1</span>
                    <p className="app-call-to-action-card-text">
                      Compreendemos as necessidades do cliente e
                      disponibilizamos equipamentos adicionais para serem
                      acoplados ao projeto, como impressora, leitor biométrico,
                      base/berço para pin-pad entre outros.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>2</span>
                    <p className="app-call-to-action-card-text">
                      Desenvolvemos aplicativos embarcados nos totens,
                      estruturamos a arquitetura do projeto e definimos os
                      recursos necessários para o desenvolvimento da solução.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>3</span>
                    <p className="app-call-to-action-card-text">
                      Efetuamos a validação detalhada do projeto e juntamente
                      com o aval do cliente, liberamos o projeto.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="app-call-to-action-text ">
              Fabricamos totem digital de autoatendimento e desenvolvemos
              aplicativos embarcados que conectam sua empresa ao seus clientes,
              de acordo com a necessidade de cada projeto.
            </p>
          </div>
        </div>
      </div>
      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e
              comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
