import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MapContainer, Icon, Submit, Input, Form, Helmet, Select } from "../../components";
import { mdiCheck } from "@mdi/js";


export default ({ params, notice, type, busy, actions }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Trabalhe Conosco"
      description="Envie seu currículo para a iTS Enterprise Innovation. A iTS é especilista em totens digitais, desenvolvimento de aplicativo mobile e consultoria em tecnologia. Faça parte de uma equipe incrível e que adora tecnologia!"
      canonical="https://itstecnologia.digital/trabalhe-conosco/"
    />
    <div className="main">

      <div className="section cover cover-page quarter-height work-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Trabalhe Conosco</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Trabalhe Conosco</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section contact-page white-bg">
        <div className="section mb-5">
          <ul className="nav justify-content-center">
            <li className="nav-item "><Link to="/contato/" className="nav-link" title="Fale Conosco">Fale Conosco</Link></li>
            <li className="nav-item active"><Link to="/trabalhe-conosco/" className="nav-link" title="Trabalhe Conosco">Trabalhe Conosco</Link></li>
          </ul>
        </div>

        <div className="section" style={{ marginBottom: 500 }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-9 col-lg-7">
                <h2 className="text-center mb-2">
                  A iTS oferece vagas em diversas áreas do conhecimento em um ambiente de trabalho inovador e
                  dinâmico, propício para que sua força de trabalho possa se desenvolver de forma profissional.
                </h2>
                <h2 className="text-center mb-5">
                  Envie seu currículo e faça parte de nosso banco de talentos. Assim que
                  surgirem vagas compatíveis com o seu perfil e qualificações, entraremos em contato.
                </h2>
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    <div className="section features-item">
                      <h6 className="mb-3">O que oferecemos?</h6>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Ética e transparência</p>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Metodologia de trabalho diferenciada</p>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Crescimento profissional</p>
                      <p className="mb-0">
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />

                        Ambiente de trabalho agradável</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="section features-item">
                      <h6 className="mb-3">O que valorizamos?</h6>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Respeito ao cliente e grupo de trabalho</p>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Interesse na evolução pessoal, profissional e
                                    aprendizado</p>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Iniciativa, criatividade e proatividade</p>
                      <p className="mb-0">
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Comprometimento</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="section features-item">
                      <h6 className="mb-3">Áreas de atuação</h6>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Produção/Fabricação</p>
                      <p>
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Suporte e Assistência Técnica</p>
                      <p className="mb-0">
                        <Icon path={mdiCheck} size={0.7} color="#A52A2A" style={{ marginRight: 8 }} />
                        Projetos</p>
                    </div>
                  </div>
                </div>
                <Form onSubmit={actions.onSubmit}>
                  <div className="row">
                    <div className={`alert alert-${type}`} hidden={!notice.length}>
                      {notice}
                    </div>
                    <div className="col-12">
                      <Input
                        name="name"
                        id="contact_name"
                        label="Nome"
                        onChange={actions.onChange}
                        value={params.name}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <Input
                        name="phone"
                        id="contact_phone"
                        label="Telefone"
                        onChange={actions.onChange}
                        maxLength={15}
                        value={params.phone}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <Input
                        name="email"
                        id="contact_email"
                        label="E-mail"
                        onChange={actions.onChange}
                        value={params.email}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <Select
                        name="area"
                        id="area"
                        label="Área de Atuação"
                        onChange={actions.onChange}
                        value={params.area}
                        required
                      >
                        <option value="">Selecione</option>
                        <option value="Tecnologia">Tecnologia</option>
                        <option value="Desenvolvimento">Desenvolvimento</option>
                        <option value="Projetos">Projetos</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Engenharia">Engenharia</option>
                        <option value="Administrativo e Financeiro">Administrativo e Financeiro</option>
                        <option value="Produção/Fabricação">Produção/Fabricação</option>
                        <option value="Outros">Outros</option>
                      </Select>
                    </div>
                    <div className="col-12">
                      <div className="button-wrap">
                        <label className="new-button" htmlFor="uploaded_file">Enviar Cúrriculo</label>
                        <input type="file" name="uploaded_file" id="uploaded_file" />
                      </div>
                    </div>
                  </div>
                  <Submit title="Enviar Mensagem" busy={busy} />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: 500, position: "absolute", bottom: 0, width: "100%" }}>
        <MapContainer />
      </div>

    </div>
  </Fragment>
);
