import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet, CaseSection, TotensCaseSection } from "../../components";
import { CasesTotensData, CaseAppsData } from "./data";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Cases Desenvolvimento App e Totem"
      description="Conheça alguns de nossos cases de desenvolvimento de Aplicativos Mobile android e iOS e também cases de Totens de Autoatendimento e aplicativos embarcados desenvolvidos para os totens."
    />
    <div className="main">
      <div className="section cover cover-page quarter-height cases-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Cases</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Cases</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section contact-page white-bg">
        <div className="container">
          <div className="contact-page">
            <h2 className="toten-subtitle">Aplicativos</h2>
            <p className="cases-subtitle">
              Conheça alguns dos aplicativos mobile desenvolvidos pela iTS.
            </p>
            <CaseSection data={CaseAppsData} />
          </div>

          <h2 className="toten-subtitle">Totens</h2>
          <p className="cases-subtitle">
            Conheça algumas soluções de totens de autoatendimento desenvolvida
            pela iTS.
          </p>
          <TotensCaseSection data={CasesTotensData} />
        </div>
      </div>

      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e
              comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
