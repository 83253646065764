import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totens Tablet Smart e Smart Desk"
      description="Conheça a linha de Totens Tablet Smart e Smart desk, equipamentos versáteis e de baixo custo, fabricados pela iTS Enterprise Innovation. O totem Smart e Smart Desk são ideiais para pesquisa de satisfação, geração de senha eletrônica e outras soluções."
      canonical="https://itstecnologia.digital/totens-digitais/totens-tablet/smart/"
    />
    <div className="main">

      <div className="section cover cover-page quarter-height totens-smart-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Totens Tablet</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/" title="Totens Digitais">
                    Totens Digitais
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/totens-tablet/" title="Totens Tablet">
                    Totens Tablet
                  </Link>
                </li>
                <li className="breadcrumb-item active">Smart</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg about-content p-bottom-70 no-bg-img">
        <div className="container">
          <h2>Smart e Smart Desk</h2>
          <div className="row gutters">
            <div className="col-12 toten-explanation">
              <p>
                Os totens tablets Smart foram desenvolvidos para <span>utilização em diversos setores </span> e
                <span> aplicações </span>.
              </p>
              <p>
                São totens pequenos, característica que os tornam <span> equipamentos versáteis </span> porque se
                acomodam em qualquer ambiente, desde lojas, consultórios, hall de prédio, entre outros locais.
                Em ambientes maiores, podem ser alocados mais de um totem, recurso este favorecido pelo seu <span>
                  baixo custo</span>.
              </p>
              <p>
                A sua <span>facilidade de transporte</span> e a aplicação de identidade visual através de
                <span> plotagem</span>, faz com que a sua <span>utilização em feiras</span> e <span>eventos</span> seja
bastante procurada.
              </p>
              <p>
                Em ambiente com grande circulação de pessoas, o totem Smart é bastante <span>resistente</span> e
                <span> seguro</span>. O gabinete é protegido contra maresia, eletroestática e corrosão, o pedestal
                pode ser fixo ou com ventosas. O acesso ao tablet é feito somente com chave.
              </p>
              <p>
                Este <span>tipo</span> de totem é <span>frequentemente</span> utilizado para <span>pesquisas de
                satisfação</span>, <span>geração de senha eletrônica</span> e também <span>outras soluções </span>
                com acesso à internet.
              </p>
              <p>
                Sendo um computador portátil e tela sensível ao toque, possibilita também a<span> instalação de
                aplicativos mobile</span>.
              </p>
            </div>
            <div className="col-12">
              <h2 className="title-alt">Destaques</h2>
              <ul className="check-list-structure feat">
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Ocupam pouco espaço;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Fáceis de Transportar;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Resistentes;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Seguros;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Custo X Benefício;</span>
                </li>
              </ul>
            </div>
          </div >
        </div >
      </div>

      <div className="section gallery-content">
        <div className="container">
          <h2>Galeria</h2>
          <div className="gallery-row">
            <div className="gallery-col">
              <img title="Totem Tablet, na Sancor Seguros" src={require("../../assets/galleries/smart/totem-tablet-linha-smart-sancor-seguros.jpg")} alt="Equipamento totem tablet linha smart com pedestal no stand da Sancor Seguros." />
              <img title="Totem Tablet no evento da TicNova" src={require("../../assets/galleries/smart/totem-tablet-linha-smart-ticnova.jpg")} alt="Equipamentos totens tablet linha smart com pedestal no evento TicNova 2017." />
            </div>
            <div className="gallery-col">
              <img title="Totem Tablet, elaborado para a Votorantim" src={require("../../assets/galleries/smart/totem-tablet-linha-smart-votorantim.jpg")} alt="Equipamento totem tablet linha smart com pedestal para a Votorantim." />
              <img title="Totem Tablet, no evento da Sebrare" src={require("../../assets/galleries/smart/totem-tablet-smart-sebrae.jpg")} alt="Equipamento totens tablet linha smart com pedestal no evento da Sebrae." />
            </div>
            <div className="gallery-col">
              <img title="Totem Tablet, de pesquisa de satisfação, na Havan" src={require("../../assets/galleries/smart/totem-tablet-smart-havan.jpg")} alt="Equipamento totem tablet linha smart com pedestal para pesquisa de satisfação da Havan." />
            </div>
            <div className="gallery-col">
              <img title="Totem Tablet, sem pedestal, na Havan" src={require("../../assets/galleries/smart/totem-tablet-smart-desk-havan.jpeg")} alt="Equipamento totem tablet linha smart desk sem pedestal para solicitação de atendimento na Havan." />
            </div>
          </div>
        </div>
      </div>

      <div className="section dark-bg padding-70 resources-content no-bg-img">
        <div className="container">
          <div className="row">
            <h2>Características</h2>
          </div>
          <div className="row gutters">
            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/anti-impacto.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Posicionamento de tela</h3>
                <p>Possibilidade de ajustar a orientação da tela em formato horizontal ou vertical.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/alto-desempenho.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Alto Desempenho</h3>
                <p>Equipamento de alto desempenho, contando com armazenamento SSD para maior agilidade e comodidade
                        no atendimento.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/bivolt.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Bivolt Automático</h3>
                <p>Pensando na variedade de ambientes, os equipamentos possuem fonte bivolt automática, se adaptando
                        a redes 110v ou 220v sem necessidade de ajuste manual.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/chave-liga-desliga.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Suporte</h3>
                <p>Além do modelo montado com a base em pedestal, também podemos disponibilizar o modelo Totem Smart
                    Desk (base para utilização em balcão) ou até mesmo somente a tela para fixação em suportes de
                        TV.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/suporte.png")} alt="complementary-icon" />
              </div>
              <div className="content">
                <h3>Flexibilidade</h3>
                <p>Por se tratar de um equipamento com sistema operacional Android, existe a possibilidade de
                        utilização de qualquer aplicativo mobile disponível na Google Play.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Entre em contato, traga seu projeto para a iTS!</h3>
              </div>
              <div className="col-12 col-md-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg padding-70 resources-content">
        <div className="container">
          <h2 className="dark-red-color">Especificações Técnicas</h2>
          <table className="table-striped table">
            <thead>
              <tr>
                <th>Itens</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gabinete</td>
                <td>Metal, disponível na cor Preto ou Branca. </td>
              </tr>
              <tr>
                <td>Proteções do gabinete</td>
                <td>Maresia, Estática e Corrosão</td>
              </tr>
              <tr>
                <td>Suporte</td>
                <td>Com pedestal (SMART) <br /> Suporte de mesa/balcão (SMART DESK)</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>10.1 polegadas</td>
              </tr>
              <tr>
                <td>Touch Screen</td>
                <td>1 toque</td>
              </tr>
              <tr>
                <td>Processador</td>
                <td>Quad Core</td>
              </tr>
              <tr>
                <td>Memória</td>
                <td>1GB</td>
              </tr>
              <tr>
                <td>Armazenamento</td>
                <td>Flash 16GB</td>
              </tr>
              <tr>
                <td>Som</td>
                <td>Sim</td>
              </tr>
              <tr>
                <td>Sistema Operacional</td>
                <td>Android</td>
              </tr>
              <tr>
                <td>Pés</td>
                <td>Pés fixos<br />
                  Ventosas <small>(OPCIONAL)</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/smart-pay/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Smart Pay</h3>
                      <p className="products-recall-description">
                        Totem com pedestal e base/berço para Pin-Pad.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment >
);
