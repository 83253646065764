import validate from "validate.js";

class Work {
  constructor(attrs = {}) {
    this.attrs = attrs;
    this.errors = {};
  }

  async send() {
    this.validate(this.attrs);
    if (!this.errors) return this.submit();
    return Promise.reject(this.errors);
  }

  validate(attrs) {
    this.errors = validate(attrs, Work.rules, { format: 'flat', fullMessages: false });
    return this.errors;
  }

  submit() {
    const body = new FormData();
    body.append('name', this.attrs.name);
    body.append('phone', this.attrs.phone);
    body.append('email', this.attrs.email);
    body.append('area', this.attrs.area);
    var filedata = document.querySelector('input[type="file"]').files[0];
    body.append('uploaded_file', filedata, 'curriculo_candidato.pdf');

    fetch('/sendWorkMail.php', { method: 'POST', body: body });
  }
}

Work.rules = {
  name: {
    presence: {
      message: "nome é obrigatório"
    },
    length: {
      minimum: 3,
      message: "nome é muito curto"
    }
  },
  email: {
    presence: {
      message: "e-mail é obrigatório"
    },
    email: {
      message: "e-mail não é válido"
    }
  },
  phone: {
    format: {
      pattern: /^(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
      flags: "ig",
      message: "telefone não é válido"
    }
  },
  area: {
    presence: {
      message: "área é obrigatória"
    },
    length: {
      minimum: 3,
      message: "Selecione uma área de atuação"
    }
  }
};

export default Work;
