import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MapContainer, Form, Input, TextArea, Select, Submit, Helmet } from "../../components";

export default ({ params, notice, type, busy, actions }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Fale Conosco"
      description="Entre em contato com a iTS Enterprise Innovation através do nosso formulário de contato 'Fale Conosco'. Basta preencher os campos e enviar a mensagem, retornamos o mais breve possível."
      canonical="https://itstecnologia.digital/contato/"
    />
    <div className="main">

      <div className="section cover cover-page quarter-height contact-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Contato</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                    </Link>
                </li>
                <li className="breadcrumb-item active">Contato</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section contact-page white-bg" style={{ marginBottom: 500 }}>
        <div className="section mb-5">
          <ul className="nav justify-content-center">
            <li className="nav-item active">
              <Link to="/contato/" className="nav-link" title="Fale Conosco">Fale Conosco</Link>
            </li>
            <li className="nav-item ">
              <Link to="/trabalhe-conosco/" className="nav-link" title="Trabalhe Conosco">Trabalhe Conosco</Link>
            </li>
          </ul>
        </div>

        <div className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-9 col-lg-7">
                <h2 className="text-center">
                  Para entrar em contato com a iTS, basta preencher o formulário abaixo,
                  enviar sua mensagem e retornaremos o mais breve possível.
                  <br/>
                  Estamos disponíveis para esclarecer todas as suas dúvidas!
                  Totem Digital de autoatendimento, Desenvolvimento de aplicativos mobile, Consultoria em tecnologia.
                </h2>

                <Form onSubmit={actions.onSubmit}>
                  <div className="row">
                    <div className={`alert alert-${type}`} hidden={!notice.length}>
                      {notice}
                    </div>
                    <div className="col-12">
                      <Input
                        name="name"
                        id="contact_name"
                        label="Nome"
                        onChange={actions.onChange}
                        value={params.name}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        name="company"
                        id="company_email"
                        label="Empresa"
                        onChange={actions.onChange}
                        value={params.company}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        name="phone"
                        id="contact_phone"
                        label="Telefone"
                        onChange={actions.onChange}
                        maxLength={15}
                        value={params.phone}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <Input
                        name="email"
                        id="contact_email"
                        label="E-mail"
                        onChange={actions.onChange}
                        value={params.email}
                        required
                      />
                    </div>
                    <div className="col-12" style={{ marginBottom: 16 }}>
                      <Select
                        name="found"
                        id="found"
                        label="Como nos encontrou?"
                        onChange={actions.onChange}
                        value={params.found}
                        required
                      >
                        <option value="">Selecione...</option>
                        <option value="Google">Google</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Indicação">Indicação</option>
                        <option value="Outros">Outros</option>
                      </Select>
                    </div>
                    <div className="col-6">
                      <Input
                        name="neighborhood"
                        id="contact_neighborhood"
                        label="Bairro"
                        onChange={actions.onChange}
                        value={params.neighborhood}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <Input
                        name="city"
                        id="contact_city"
                        label="Cidade"
                        onChange={actions.onChange}
                        value={params.city}
                        required
                      />
                    </div>
                    <div className="col-2">
                      <Select
                        name="state"
                        id="state"
                        label="UF"
                        onChange={actions.onChange}
                        value={params.state}
                        required
                      >
                        <option value="">UF</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MG">MG</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </Select>
                    </div>
                  </div>
                  <TextArea
                    name="message"
                    id="contact_message"
                    label="Escreva sua mensagem"
                    onChange={actions.onChange}
                    value={params.message}
                    required
                  />
                  <Submit title="Enviar Mensagem" busy={busy} />
                </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 500, position: "absolute", bottom: 0, width: "100%" }}>
        <MapContainer />
      </div>
    </div>
  </Fragment>
);
