import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
  HomeController,
  AboutUsController,
  TotensController,
  AppsController,
  CasesController,
  CasesMobileEgorjetaController,
  CasesMobileIdeloController,
  CasesMobileLausController,
  CasesMobileNinkyController,
  CasesTotensHavanController,
  CasesTotensParanaController,
  CasesTotensLeroyController,
  CasesTotensVotorantimController,
  CasesTotensTicNovaController,
  CasesTotensCondorController,
  ConsultingController,
  ContactController,
  TotensTabletController,
  TotensSmartController,
  TotensSmartPayController,
  TotensTableController,
  TotensAutodeskController,
  TotensChargerController,
  WorkController,
  NotFoundController,
} from "../controllers";

const Routes = ({ history }) => {
  useEffect(() => {
    const listener = history.listen(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    return () => listener.remove();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" component={HomeController} />
      <Route exact path="/sobre-nos/" component={AboutUsController} />
      <Route exact path="/totens-digitais/" component={TotensController} />
      <Route exact path="/totens-digitais/totens-tablet/" component={TotensTabletController} />
      <Route exact path="/totens-digitais/totens-tablet/smart/" component={TotensSmartController} />
      <Route exact path="/totens-digitais/totens-tablet/smart-pay/" component={TotensSmartPayController} />
      <Route exact path="/totens-digitais/mesas-touch-screen/" component={TotensTableController} />
      <Route exact path="/totens-digitais/totens-autoatendimento/" component={TotensAutodeskController} />
      <Route exact path="/totens-digitais/totens-carregadores/" component={TotensChargerController} />
      <Route exact path="/desenvolvimento-aplicativos/" component={AppsController} />
      <Route exact path="/consultoria-em-tecnologia/" component={ConsultingController} />
      <Route exact path="/contato/" component={ContactController} />
      <Route exact path="/cases/" component={CasesController} />
      <Route exact path="/cases/aplicativo-mobile/idelo/" component={CasesMobileIdeloController} />
      <Route exact path="/cases/aplicativo-mobile/laus/" component={CasesMobileLausController} />
      <Route exact path="/cases/aplicativo-mobile/ninky/" component={CasesMobileNinkyController} />
      <Route exact path="/cases/aplicativo-mobile/egorjeta/" component={CasesMobileEgorjetaController} />
      <Route exact path="/cases/totem-digital/havan/" component={CasesTotensHavanController} />
      <Route exact path="/cases/totem-digital/parana-clinicas/" component={CasesTotensParanaController} />
      <Route exact path="/cases/totem-digital/leroy-merlin/" component={CasesTotensLeroyController} />
      <Route exact path="/cases/totem-digital/votorantim/" component={CasesTotensVotorantimController} />
      <Route exact path="/cases/totem-digital/ticnova/" component={CasesTotensTicNovaController} />
      <Route exact path="/cases/totem-digital/condor/" component={CasesTotensCondorController} />
      <Route exact path="/trabalhe-conosco/" component={WorkController} />
      <Route exact path="/404/" component={NotFoundController} />
      <Redirect from='*' to='/404/' />
    </Switch>
  );
};

export default withRouter(Routes);
