import React, { Component } from "react";
import { CasesTotensParanaPage } from "../pages";

class CasesTotensParanaController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Parana",
        image: require("../assets/supporters/parana_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo",
        segment: "Hospitais, Clínicas",
        solution: "Totem de autoatendimento com Biometria Facial"
      }
    };
  }

  render = () => <CasesTotensParanaPage {...this.state} {...this.props} />;
}

export default CasesTotensParanaController;
