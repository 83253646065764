import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { bounce } from "react-animations";
import { mdiArrowDown } from "@mdi/js";
import styled, { keyframes } from "styled-components";

import { Icon, Helmet } from "../../components";
import believers from "./_believers";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Bounce = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;

const totem = require("../../assets/totem-digital-autoatendimento.jpg");
const apps = require("../../assets/aplicativos-para-empresas.jpg");
const startup = require("../../assets/desenvolvimento-de-aplicativo-mobile.jpg");
const consulting = require("../../assets/consultoria-em-tecnologia-e-projetos.jpg");

const listItems = believers.map((e, index) => (
  <div key={index}>
    <a href={e.url} title={e.name} rel="noopener noreferrer" target="_blank">
      <img
        title={e.name}
        style={{ maxWidth: 120, marginLeft: "auto", marginRight: "auto" }}
        src={e.logo}
        alt={e.name}
      />
    </a>
  </div>
));

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        background: "#FF1930",
        height: 40,
        width: 40,
        color: "#FFF",
        paddingBottom: 10,
        position: "absolute",
        right: -48,
        bottom: "30%"
      }}
      onClick={onClick}
    >
      <span
        style={{
          fontSize: 30,
          marginLeft: 14,
          position: "relative",
          bottom: 5
        }}
      >
        ›
      </span>
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        background: "#FF1930",
        height: 40,
        width: 40,
        color: "#FFF",
        paddingBottom: 10,
        position: "absolute",
        left: -48,
        bottom: "30%"
      }}
      onClick={onClick}
    >
      <span
        style={{
          fontSize: 30,
          marginLeft: 14,
          position: "relative",
          bottom: 5
        }}
      >
        ‹
      </span>
    </div>
  );
}

const settings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 525,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const executeScroll = () => window.scrollTo({ top: 1000, behavior: "smooth" });

export default props => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Especialista em Soluções Digitais"
      description="A iTS Enterprise Innovation é especialista em soluções digitais abrangendo totem digital e aplicativos. Nossa linha de produtos contempla totem de autoatendimento, totem de pagamento e mesa touch screen. Desenvolvemos aplicativo mobile e prestamos consultoria em projetos de tecnologia."
      canonical="https://itstecnologia.digital/"
    />
    <div className="main">
      <div className="section cover cover-home">
        <div className="section mask full-height sm-full-height">
          <div className="caption text-center">
            <h2 className="text-uppercase">
              Conheça um universo de novas possibilidades em
            </h2>
            <h1>Tecnologia Interativa</h1>
            <Link to="/totens-digitais/" title="Conhecer Totens">
              Conhecer Totens
            </Link>
            <Link
              to="/desenvolvimento-aplicativos/"
              title="Conhecer Aplicativos"
            >
              Conhecer Aplicativos
            </Link>
          </div>
          <a onClick={executeScroll} href="#contente" title="Ver mais">
            <Bounce className="down-btn">
              <Icon
                path={mdiArrowDown}
                size={2}
                color="#fff"
                style={{ marginRight: 8 }}
              />
            </Bounce>
          </a>
        </div>
      </div>
      <div
        className="d-block d-md-none section intro-section alt white-bg"
        style={{ height: "120px!important" }}
        id="content"
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <div className="introCopy">
                <div className="description">
                  <div className="sectionTitle">
                    <p>Bem-vindos!</p>
                    <h2 className="title">
                      Uma rápida apresentação do que a iTS pode oferecer a você.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section intro-section white-bg">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <div className="introCopy">
                <div className="description">
                  <div className="d-none d-md-block sectionTitle">
                    <p>Bem-vindos!</p>
                    <h2 className="title">
                      Uma rápida apresentação do que a iTS pode oferecer a você.
                    </h2>
                  </div>
                  <h3>Totens Digitais</h3>
                  <div className="m-text">
                    <p>
                      Fabricamos Totens Digitais, Mesas Touch Screen, Totens
                      Tablet e Totens carregador de celular.
                    </p>
                    <p>
                      Os produtos desenvolvidos pela iTS destacam-se pelo alto
                      desempenho, resistência, durabilidade e tecnologia
                      nacional.
                    </p>
                    <p>
                      Além dos destaques de robustez mencionada,
                      disponibilizamos a flexibilidade de customizar projetos
                      específicos e até mesmo desenvolver novos projetos de
                      acordo com a necessidade dos nossos clientes.
                    </p>
                  </div>
                  <Link
                    to="/totens-digitais/"
                    className="alt"
                    title="Saiba Mais"
                  >
                    Saiba Mais
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                title="Totem de autoatendimento"
                className="home-img-wrapper d-none d-md-block"
                src={totem}
                alt="Tela de equipamento totem de autoatendimento."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section intro-section grey-bg">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <img
                title="Desenvolvimento Mobile"
                className="home-img-wrapper-left d-none d-md-block"
                src={apps}
                alt="Desenvolvimento de aplicativo mobile para empresas."
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="introCopy">
                <div className="description">
                  <h3>Aplicativos para Empresas</h3>
                  <div className="m-text">
                    <p>
                      Algumas empresas possuem demandas específicas e necessitam
                      de soluções inteligentes para automatizar seus processos.
                    </p>
                    <p>
                      Desenvolvemos aplicativos mobile e também aplicativos
                      embarcados em totens digitais que possibilitam promover
                      divulgação, agilidade e qualidade no recebimento dos
                      dados, além da otimização do tempo.
                    </p>
                  </div>
                  <Link to="/desenvolvimento-aplicativos/" title="Saiba Mais">
                    Saiba Mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section intro-section white-bg">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <div className="introCopy">
                <div className="description">
                  <h3>Aplicativos para Startups</h3>
                  <div className="m-text">
                    <p>
                      Somos especialistas no desenvolvimento de aplicativos
                      mobile iOS (Apple) e Android (Google) e soluções Web,
                      destinados a empreendedores.
                    </p>
                    <p>
                      Unimos a expertise tecnológica ao conhecimento do negócio,
                      visando propor e entregar soluções digitais criativas.
                    </p>
                    <p>
                      Empreendedores enxergam oportunidades em mercados ainda
                      não explorados. Através da mentoria e consultoria,
                      ajudamos os empreendedores a viabilizar sua ideia
                      tirando-a do papel e transformando-o em um produto
                      efetivo.
                    </p>
                  </div>
                  <Link
                    to="/desenvolvimento-aplicativos/"
                    className="alt"
                    title="Saiba Mais"
                  >
                    Saiba Mais
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                title="Prototipação e Desenvolvimento Mobile"
                className="home-img-wrapper d-none d-md-block"
                src={startup}
                alt="Prototipação e desenvolvimento de aplicativo mobile."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section intro-section grey-bg">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <img
                title="Consultoria em Tecnologia"
                className="home-img-wrapper-left d-none d-md-block"
                src={consulting}
                alt="Consultoria de tecnologia para projetos de sites, sistemas e aplicativos mobile."
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="introCopy">
                <div className="description">
                  <h3>Consultoria em Tecnologia</h3>
                  <div className="m-text">
                    <p>
                      Prestamos consultoria em projetos de tecnologia.
                      Frequentemente somos procurados por clientes que buscam
                      totens digitais ou o desenvolvimento de aplicativos
                      mobile, para um negócio repetível e escalável, e após os
                      contatos iniciais o que se percebe é que os projetos ainda
                      necessitam de consolidação e estruturação do negócio.
                    </p>
                    <p>
                      Antes de ser dado início ao desenvolvimento da solução,
                      efetuamos a consultoria elaborando a validação do conceito
                      do projeto considerando: sustentabilidade, geração de
                      valor, escalabilidade, viabilidade técnica, visão contábil
                      e financeira entre outras questões.
                    </p>
                  </div>
                  <Link to="/consultoria-em-tecnologia/" title="Saiba Mais">
                    Saiba Mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section about-company blue-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h2>
                <small>Somos uma empresa de</small>
                <br />
                Soluções em Tecnologia
              </h2>
              <p>
                A iTS Enterprise Innovation é especializada em desenvolver
                plataformas de soluções de negócios, contemplando totens
                digitais, mentoria/consultoria em negócios de tecnologia,
                soluções interativas para totens, soluções web e desenvolvimento
                de aplicativo mobile.
              </p>
              <p className="mb-0">
                Unimos a expertise tecnológica ao conhecimento do negócio, para
                entregar soluções digitais completas.
              </p>
            </div>
          </div>
          <div className="d-none d-sm-none d-md-block d-lg-block col-md-6 col-lg-6 image-holder image-holder-right about-image"></div>
        </div>
      </div>
      <div className="section clients-section white-bg">
        <div className="container">
          <h2>Quem Acredita</h2>
          <Slider {...settings}>{listItems}</Slider>
        </div>
      </div>
      <div className="section how-it-works">
        <div className="container">
          <div className="section side-dark left">
            <div className="row mobile-row-pd">
              <div className="col-12 col-lg-3">
                <div className="section intro">
                  <div className="section intro-title">
                    <p>Como trabalhamos</p>
                    <h2>O PROCESSO</h2>
                    <Link to="/sobre-nos/" title="Conheça a iTS">
                      Conheça a iTS
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="row gutter first-row">
                  <div className="col-12 col-lg-5">
                    <div className="section item">
                      <i className="dripicons-message"></i>
                      <h5>Requisitos</h5>
                      <span className="no">1.</span>
                      <p>
                        Compreendemos as necessidades dos clientes, através de
                        questionamentos e análises que buscam esclarecer os
                        objetivos e restrições do projeto, sendo estes definidos
                        em conjunto com o cliente.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="section item">
                      <i className="dripicons-gear"></i>
                      <h5>Estruturação</h5>
                      <span className="no">2.</span>
                      <p>
                        Com base nos requisitos especificados, estruturamos a
                        arquitetura do projeto, definindo as tecnologias e
                        recursos necessários para o desenvolvimento do produto.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gutter cl-row">
                  <div className="col-12 col-lg-7">
                    <div className="section item">
                      <i className="dripicons-code"></i>
                      <h5>Desenvolvimento</h5>
                      <span className="no">3.</span>
                      <p>
                        Transformamos em linhas de código os objetivos e
                        restrições pré-definidos em um produto estruturado e de
                        qualidade para atender seu negócio, valorizando a
                        experiência de utilização do usuário.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div className="section item">
                      <i className="dripicons-rocket"></i>
                      <h5>Lançamento</h5>
                      <span className="no">4.</span>
                      <p>
                        Após executarmos uma detalhada homologação – testes – o
                        produto é então liberado para acesso do cliente, podendo
                        ser uma versão ou produto final.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to="/sobre-nos/"
                className="mobile-its-btn"
                title="Conheça a iTS"
              >
                Conheça a iTS
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action">
        <div className="section mask">
          <div className="container text-center">
            <h2>
              Todo cliente é especial, não importa o tamanho do projeto.
              <br />
              Traga seu projeto para a iTS!
            </h2>
            <Link to="/contato/" title="Entre em contato">
              Entre em contato
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
