import React from "react";
import { Link } from "react-router-dom";
import _ from "styled-components";

export default ({ data }) => {
  if (data)
    return (
      <>
        <CaseWrapper>
          {data.map((e, index) => (
            <Case key={index} to={e.link} >
              <Section>
                <Logo src={e.image} />
              </Section>
              <Section>
                <Title>{e.title}</Title>
                <Description>{e.description}</Description>
              </Section>
            </Case>
          ))}
        </CaseWrapper>
      </>
    );

  return null;
};

const CaseWrapper = _.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const Case = _(Link)`
  display: flex;
  width:33.33%;
  flex-direction: column;
  padding: 18px 20px;
  
  @media (max-width: 768px) {
    width: 50%;
  }
  &:hover {
    opacity: 1;
  }
`;

const Section = _.div`
  width:100%;
`;

const Logo = _.img`
  margin: 0 auto;
  display: block;
  width: auto;
  height: 96px;
  padding: 0 12px;
`;

const Title = _.h3`
  text-align: center;
  font-size: 1.75em;
  font-weight: 300;
  text-align: center;
  color: #333;
  margin: 8px 0;
`;

const Description = _.div`
  font-size: 17px;
  text-align: center;
`;
