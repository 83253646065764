import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "styled-components";

import { Header, Footer } from "../../components";

export default withRouter(({ children, history }) => {

  const [transparent, setTransparent] = useState(true);
  const [RenderFooter, setRenderFooter] = useState(false);
  const isTransparent = history.location.pathname === "/";
  const whichPage = history.location.pathname;

  const listener = () => (setTransparent(window.scrollY < 170));

  useEffect(() => {
    if (history.location.pathname === "/") {
      window.addEventListener('scroll', listener);
    }

    if (whichPage === "/404/") {
      setRenderFooter(true);
    } else {
      setRenderFooter(false);
    }

  }, [history.location, isTransparent, whichPage]);

  useEffect(() => {
    setTransparent(isTransparent);
  }, [history.location, isTransparent]);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', listener);
    }
  }, [history.location]);

  useEffect(() => {
    return () => {
      if (window.innerWidth < 992) {
        document.getElementById("navbar-toggler").click();
      }
    }
  }, [history.location]);

  return (
    <Wrapper>
      <Header transparent={transparent} activePage={whichPage} />
      {children}

      <Footer shouldRender={RenderFooter} />
    </Wrapper>
  );
});

const Wrapper = _.div`
  overflow-x: hidden;
`;
