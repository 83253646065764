import React from "react";
import { Link } from "react-router-dom";
import _ from "styled-components";

export default ({ shouldRender }) => {
  if (shouldRender) {
    return null;
  }
  return (
    <div className="footer">
      <div className="section upper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 mb-xs-1">
              <Link to="/" title="iTS Enterprise Innovation" className="brand">
                <img
                  src={require("../../assets/logo-branca.png")}
                  title="Logo iTS Branca"
                  alt="iTS Enterprise Innovation"
                />
              </Link>
              <h6 className="mt-4">Causa que apoiamos</h6>
              <div className="footer-cause-wrapper">
                <div className="causes-container">
                  <a
                    href="http://pequenoprincipe.org.br/sobre-o-complexo/"
                    className="footer-cause"
                    title="Fundação Pequeno Príncipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      height="80px"
                      title="Logo Pequeno Príncipe"
                      src={require("../../assets/logo-pequeno-principe.png")}
                      alt="Fundação Pequeno Príncipe"
                    />
                  </a>
                  <a
                    href="http://www.4pinheiros.org.br/"
                    className="footer-cause"
                    title="Meninos de 4 Pinheiros"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      height="80px"
                      title="Logo 4 Pinheiros"
                      src={require("../../assets/logo-4-pinheiros.png")}
                      alt="Meninos de 4 Pinheiros"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-xs-1">
              <h6>Navegação</h6>
              <List className="nav flex-column">
                <Item className="nav-item ">
                  <Link to="/" className="nav-link footer-link" title="Home">
                    Home
                  </Link>
                </Item>
                <Item className="nav-item ">
                  <Link
                    to="/sobre-nos/"
                    className="nav-link footer-link"
                    title="Sobre Nós"
                  >
                    Sobre Nós
                  </Link>
                </Item>
                <Item className="nav-item ">
                  <Link
                    to="/totens-digitais/"
                    className="nav-link footer-link"
                    title="Totens Digitais"
                  >
                    Totens Digitais
                  </Link>
                </Item>
                <Item className="nav-item ">
                  <Link
                    to="/desenvolvimento-aplicativos/"
                    className="nav-link footer-link"
                    title="Desenvolvimento de Aplicativos"
                  >
                    Desenvolvimento de Aplicativos
                  </Link>
                </Item>
                <Item className="nav-item ">
                  <Link
                    to="/consultoria-em-tecnologia/"
                    className="nav-link footer-link"
                    title="Consultoria em tecnologia"
                  >
                    Consultoria em tecnologia
                  </Link>
                </Item>
                <Item className="nav-item active">
                  <Link
                    to="/cases/"
                    className="nav-link footer-link"
                    title="Cases"
                  >
                    Cases
                  </Link>
                </Item>
                <Item className="nav-item active">
                  <Link
                    to="/contato/"
                    className="nav-link footer-link"
                    title="Contato"
                  >
                    Contato
                  </Link>
                </Item>
              </List>
            </div>
            <div className="col-12 col-md-3 mb-xs-1">
              <h6>Continue conectado</h6>
              <List className="nav flex-column">
                <Item className="nav-item">
                  <ExternalLink
                    target="_blank"
                    href="https://www.facebook.com/iTSEnterpriseInnovation/"
                    className="nav-link footer-link"
                    title="Facebook"
                  >
                    Facebook
                  </ExternalLink>
                </Item>
                <Item className="nav-item">
                  <ExternalLink
                    target="_blank"
                    href="https://www.linkedin.com/company/15263913/"
                    className="nav-link footer-link"
                    title="Linkedin"
                  >
                    Linkedin
                  </ExternalLink>
                </Item>
                <Item className="nav-item">
                  <ExternalLink
                    target="_blank"
                    href="https://www.instagram.com/itsenterpriseinnovation/"
                    className="nav-link footer-link"
                    title="Instagram"
                  >
                    Instagram
                  </ExternalLink>
                </Item>
                {/* <Item className="nav-item">
                  <ExternalLink target="_blank" href="https://pt.slideshare.net/itsenterpriseinnovation" className="nav-link footer-link" title="Slideshare">Slideshare</ExternalLink>
                </Item> */}
              </List>
            </div>
            <div className="col-12 col-md-3 mb-xs-1">
              <h6>Contato</h6>
              <p>
                <a
                  className="footer-link"
                  rel="noopener noreferrer"
                  href="http://maps.google.com/?q=Curitiba+Parana+Brasil
                  title="Google Maps"
                  target="_blank"
                >
                  Curitiba -
                  PR
                  <br />
                  Brasil.
                </a>
              </p>
              <p className="mb-0">
                <a
                  className="footer-link"
                  href="tel:41998426952"
                  title="Comercial: (41) 98411-6553"
                >
                  <strong>Comercial:</strong> (41) 98411-6553
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section lower text-center">
        <small>
          Todos os direitos reservados © 2019 • iTS Enterprise Innovation
        </small>
      </div>
    </div>
  );
};

const List = _.ul``;
const Item = _.li``;

const ExternalLink = _.a``;
