import React, { Component } from "react";
import { CasesTotensLeroyPage } from "../pages";

class CasesTotensLeroyController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Leroy",
        image: require("../assets/supporters/leroy_merlin_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo, Totem Tablet, Desenvolvimento de aplicativo embarcado, Desenvolvimento de Painel administrativo",
        segment: "Comércio de Varejo",
        solution: "Totem de autoatendimento com desenvolvimento de aplicativo embarcado, mapa e promoções."
      }
    };
  }

  render = () => <CasesTotensLeroyPage {...this.state} {...this.props} />;
}

export default CasesTotensLeroyController;
