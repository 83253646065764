import React, { Component } from "react";
import { CasesTotensEgorjetaPage } from "../pages";

class CasesTotensEgorjetaController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "E-gorjeta",
        image: require("../assets/cases/egorjeta-full-logo.png"),
        category:
          "Desenvolvimento de aplicativos mobile, Desenvolvimento mobile, Desenvolvimento de app",
        segment: "Empreendedores, Investidores, Startup",
        solution: "Desenvolvimento de aplicativos mobile iOS e Android",
        playStore:
          "https://play.google.com/store/apps/details?id=br.com.egorjeta",
        appStore: "https://apps.apple.com/br/app/e-gorjeta/id1476431314"
      }
    };
  }

  render = () => <CasesTotensEgorjetaPage {...this.state} {...this.props} />;
}

export default CasesTotensEgorjetaController;
