import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totens Tablet"
      description="Conheça as linhas de Totem Tablet Smart, Smart Desk e Smart Pay fabricados pela iTS Enterprise Innovation. São equipamentos versáteis, desenvolvidos para utilização em diversos setores e aplicações. São totem utilizados para pesquisa de satisfação, eventos, gestão de filas, entre outros."
      canonical="https://itstecnologia.digital/totens-digitais/totens-tablet/"
    />
    <div className="main">
      <div className="section cover cover-page quarter-height totens-tablet-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Totens Tablet</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" title="Home">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/totens-digitais/" title="Totens Digitais">Totens Digitais</Link></li>
                <li className="breadcrumb-item active">Totens Tablet</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section white-bg p-bottom-60">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <h2 className="toten-subtitle">
                Conheça os produtos da linha Totens Tablet
              </h2>
              <div className="totens-tablet-description">
                Os totens tablet smart são totens digitais de autoatendimento compactos, seguros e que podem ser montados com base em
                pedestal ou com base para utilização em mesas ou balcões. O modelo de totem smart pay, vem acoplado com berço para equipamento
                pin-pad, viabilizando o totem tablet como um terminal de pagamento.
              </div>
            </div>
          </div>
          <div className="row m-top-40">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/smart/">
                <div className="row product-wrapper h-240">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box alt">
                        <div className="logo-smart alt">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Smart</h3>
                      <p>
                        Totem Tablet com pedestal.
                    </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/smart/">
                <div className="row product-wrapper h-240">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box alt">
                        <div className="logo-smart alt">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-desk.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Smart Desk</h3>
                      <p>
                        Totem Tablet com base para utilização em mesas/balcões.
                    </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/smart-pay/">
                <div className="row product-wrapper h-240">
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="product-box alt">
                        <div className="logo-smart alt">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart-pay.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="product-description">
                      <h3>Smart Pay</h3>
                      <p>
                        Totem com pedestal e base/berço para Pin-Pad.
                    </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/mesas-touch-screen/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/mesa-digital.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Mesas Touch Screen</h3>
                      <p className="products-recall-description">
                        Mesa Touch Screen Interativas em grandes formatos possibilitando ampla visualização
                        das informações apresentadas. Muito utilizada para apresentação de conteúdo.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-autoatendimento/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-pro.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totem de Autoatendimento</h3>
                      <p className="products-recall-description">
                        Totens digitais interativos de alto desempenho com foco na interação com usuários,
                        principalmente relacionado a atendimento.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-carregadores/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/totem-carregador.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Carregadores</h3>
                      <p className="products-recall-description">
                        Totens carregadores de bateria para dispositivos móveis, com associação de mída
                        indoor.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Fragment>
);
