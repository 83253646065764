import React, { Component } from "react";
import { CasesTotensNinkyPage } from "../pages";

class CasesTotensNinkyController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Ninky",
        image: require("../assets/cases/ninky-logo-full.png"),
        category: "Desenvolvimento de aplicativos mobile, Desenvolvimento mobile, Desenvolvimento de app",
        segment: "Empreendedores, Tatuadores",
        solution: "Desenvolvimento de aplicativos mobile iOS e Android e painel administrativo",
        playStore: "https://play.google.com/store/apps/details?id=br.com.ninky",
        appStore: "https://apps.apple.com/br/app/ninky/id1452245090"
      }
    };
  }

  render = () => <CasesTotensNinkyPage {...this.state} {...this.props} />;
}

export default CasesTotensNinkyController;
