import React from "react";
import { Link } from "react-router-dom";
import _ from "styled-components";

export default ({ data }) => {
  if (data)
    return (
      <>
        <CaseWrapper>
          {data.map((e, index) => (
            <Case key={index}>
              <Section>
                {e.laus ? (
                  <Link to={e.link}>
                    <FakeLogo>
                      <span>LG</span>
                    </FakeLogo>
                  </Link>
                ) : (
                  <Link to={e.link}>
                    <Logo src={e.image} />
                  </Link>
                )}
              </Section>
              <Section>
                <Link to={e.link}>
                  <div>
                    <Title>{e.title}</Title>
                    <Description>{e.description}</Description>
                  </div>
                </Link>
                <StoreWrapper>
                  <PlayStoreLink available={e.playStore} href={e.playStore}>
                    <MobileLogo
                      src={require("../../assets/cases/play_store.png")}
                    />
                  </PlayStoreLink>
                  <AppStoreLink available={e.appStore} href={e.appStore}>
                    <MobileLogo
                      src={require("../../assets/cases/app_store.png")}
                    />
                  </AppStoreLink>
                </StoreWrapper>
              </Section>
            </Case>
          ))}
        </CaseWrapper>
      </>
    );

  return null;
};

const CaseWrapper = _.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const Case = _.div`
  display: flex;
  width:50%;
  flex-direction: row;
  padding: 18px 0;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Section = _.div`
  width: 50%;
`;

const Logo = _.img`
  margin: 0 auto;
  display: block;
  width: auto;
  height: 96px;
  border-radius: 15%;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
`;

const FakeLogo = _.div`
  margin: 0 auto;
  background-color: #F146AC;
  height: 96px;
  width: 96px;
  border-radius: 15%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.4);

  &:hover {
    opacity: 1;
  }

  span {
    font-size: 38px;
    color: #FFF;
    font-family: "Verdana";
  }
`;

const Title = _.h3`
  font-size: 1.75em;
  font-weight:400;
  color: #333;
  line-height: normal;
`;

const Description = _.span`
  font-size: 1em;
`;

const StoreWrapper = _.div`
  margin: 12px 0;
`;

const PlayStoreLink = _.a`
  display: ${({ available }) => (available !== "" ? "inline" : "none")}
`;

const AppStoreLink = _.a`
  display: ${({ available }) => (available !== "" ? "inline" : "none")}
`;

const MobileLogo = _.img`
  max-width: 50%;
  max-height: 36px;
  padding: 0 8px 0 0;
`;
