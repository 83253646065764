import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { Icon } from "../../components";
import _ from "styled-components";
import { mdiPhone, mdiEmail, mdiMenu } from "@mdi/js";

export default props => (
  <div
    className={
      props.transparent ? "header transition" : "header transition header-page"
    }
  >
    <div className="section upper">
      <div className="container">
        <List className="nav justify-content-end">
          <Item className="nav-item">
            <Link
              to="/"
              className="nav-link disabled"
              tabIndex="-1"
              aria-disabled="true"
            >
              FALE COM A iTS
            </Link>
          </Item>
          <Item className="nav-item">
            <Link to="/contato/" className="nav-link">
              <Icon
                path={mdiEmail}
                size={0.7}
                color={props.transparent ? "#fff" : "#333"}
                style={{ marginRight: 8, marginBottom: 4 }}
              />
              falecom@itstecnologia.digital
            </Link>
          </Item>
          <Item className="nav-item">
            <Link to="/contato/" className="nav-link">
              <Icon
                path={mdiPhone}
                size={0.675}
                color={props.transparent ? "#fff" : "#333"}
                style={{ marginRight: 8, marginBottom: 4 }}
              />
              Comercial: (41) 98411-6553
            </Link>
          </Item>
        </List>
      </div>
    </div>

    <div className="section lower">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link to="/" className="navbar-brand">
            <img
              src={require("../../assets/logo-branca.png")}
              title="Logo iTS Tecnologia, Branca"
              alt="iTS Enterprise Innovation"
              className="white transition"
            />
            <img
              src={require("../../assets/its-tecnologia-horizontal.png")}
              title="Logo iTS Tecnologia"
              alt="iTS Enterprise Innovation"
              className="red transition"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
            aria-controls="navbarMain"
            aria-expanded="false"
            id="navbar-toggler"
          >
            <Icon
              path={mdiMenu}
              size={2}
              color={props.transparent ? "#fff" : "#333"}
            />
          </button>

          <div className="collapse navbar-collapse" id="navbarMain">
            <List className="navbar-nav ml-auto">
              <Item
                className={
                  props.activePage === "/" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/" className="nav-link" title="Home">
                  Home
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/sobre-nos/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/sobre-nos/" className="nav-link" title="Sobre Nós">
                  Sobre Nós
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/totens-digitais/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/totens-digitais/"
                  className="nav-link"
                  title="Totens Digitais"
                >
                  Totens Digitais
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/desenvolvimento-aplicativos/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/desenvolvimento-aplicativos/"
                  className="nav-link"
                  title="Aplicativos"
                >
                  Aplicativos
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/consultoria-em-tecnologia/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/consultoria-em-tecnologia/"
                  className="nav-link"
                  title="Consultoria"
                >
                  Consultoria
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/cases/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/cases/" className="nav-link" title="Cases">
                  Cases
                </Link>
              </Item>
              <Item
                className={
                  props.activePage === "/contato/"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/contato/" className="nav-link" title="Contato">
                  Contato
                </Link>
              </Item>
            </List>
          </div>
        </nav>
      </div>
    </div>
    <a
      href="https://api.whatsapp.com/send?phone=5541984116553"
      rel="noopener noreferrer"
      target="_blank"
      className="link-whats"
    >
      {" "}
    </a>
  </div>
);

const List = _.ul``;
const Item = _.li``;
