import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet, ShowCase, SVG } from "../../../components";
import _ from "styled-components";

export default ({ caseData }) => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Desenvolvimento mobile Idelo"
      description="Plataforma digital composta por desenvolvimento de aplicativo mobile android, solução web composta de recursos similares ao desenvolvido no app, além do desenvolvimento de portal administrativo para gestão da solução."
    />
    <div className="main">
      <div className="section case-section white-bg">
        <div className="container cover-page-alt">
          <div className="section title-section">
            <ol className="breadcrumb white-bg">
              <li className="breadcrumb-item">
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cases/" title="Cases">
                  Cases
                </Link>
              </li>
              <li className="breadcrumb-item active">Idelo</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="section app-showcase">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img className="app-logo" src={caseData.image} />
              <div>
                <h1>Aplicativo Idelo</h1>
                <p>
                  A plataforma digital Idelo, é composta por Aplicativo Mobile
                  na versão android (Google), ambiente Web e painel
                  administrativo, desenvolvido pela iTS Enterprise Innovation.
                </p>
              </div>
              <div>
                <a target="_blank" href={caseData.playStore}>
                  <MobileLogo
                    src={require("../../../assets/cases/play_store.png")}
                  />
                </a>
                {/* <a href={caseData.playStore}>
                  <MobileLogo
                    src={require("../../../assets/cases/app_store.png")}
                  />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="context-image d-none d-md-block"
        src={require("../../../assets/cases/idelo.png")}
      />

      <div className="section white-bg app-objective">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <h3>Objetivo</h3>
                <p>
                  A plataforma tem como objetivo gerar conexões e principalmente
                  negócios, além de incentivar a livre negociação, seja para
                  alguém que quer ver seu negócio decolar ou quem procura boas
                  oportunidades para investir.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                A solução digital visa, além de conectar empreendedores a
                investidores, notificar através de e-mail, push, sms e registra
                em timeline todas as ações envolvidas, possibilitando total
                gerenciamento das transações além de moderar/auditar os projetos
                publicados.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg">
        <div className="container">
          <ShowCase data={caseData} />
        </div>
      </div>

      <div className="explanation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="explanation-title">
                <div className="text-center app-call-to-action-title">
                  Tem interesse em tirar sua ideia do papel?
                </div>
                <span className="app-call-to-action-subtitle">
                  Nossa equipe de desenvolvimento possui amplo conhecimento de
                  negócio sendo especializada no desenvolvimento de aplicativos
                  mobile, projetados de acordo com a necessidade do negócio.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 social-content-parallax alt-2">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>1</span>
                    <p className="app-call-to-action-card-text">
                      Compreendemos as necessidades do cliente e através da
                      consultoria, ajudamos a formar a visão e os requisitos do
                      projeto.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>2</span>
                    <p className="app-call-to-action-card-text">
                      Estruturamos a arquitetura do projeto, definindo as
                      tecnologias e recursos necessários para o desenvolvimento
                      da solução.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="social-card alt-2">
                    <span>3</span>
                    <p className="app-call-to-action-card-text">
                      Efetuamos a validação detalhada do projeto e juntamente
                      com o aval do cliente, liberamos o projeto em produção.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="app-call-to-action-text ">
              As ideias são perfeitamente integradas ao nosso processo de design
              e desenvolvimento para produzir aplicativos modernos, elegantes e
              de alta performance.
            </p>
          </div>
        </div>
      </div>
      <div className="section call-to-action-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 separator d-none d-md-block">
              <SVG
                className="default-icon"
                src={require("../../../assets/svg/chat_bubble.svg")}
              />
            </div>
            <div className="col-12 col-md-6">
              Nós somos um time formado por pessoas talentosas, dedicadas e
              comprometidas.
            </div>
            <div className="col-12 col-md-3">
              <div className="call-to-action-btn">
                <Link to="/contato/"> Falar Com Especialista </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

const MobileLogo = _.img`
  width: 130px;
  margin: 60px 12px 20px 0;
`;
