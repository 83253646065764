import React, { Component } from "react";
import { CasesTotensHavanPage } from "../pages";

class CasesTotensHavanController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Havan",
        image: require("../assets/supporters/havan_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo, Totem Tablet, Desenvolvimento de aplicativo mobile, e desenvolvimento web",
        segment: "Comércio Varejo",
        solution: "Totem de autoatendimento com desenvolvimento de aplicativo mobile"
      }
    };
  }

  render = () => <CasesTotensHavanPage {...this.state} {...this.props} />;
}

export default CasesTotensHavanController;
