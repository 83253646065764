import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { SVG, Helmet } from "../../components";

export default () => (
  <Fragment>
    <Helmet
      title="iTS Enterprise Innovation | Totens Tablet Smart Pay"
      description="Conheça a linha de Totens Tablet Smart Pay fabricado pela iTS Enterprise Innovation. O equipamento é ideal para o recebimento de pagamentos. Tem como característica sua verstilidade, adaptação em ambientes e baixo custo. Automatize o atendimento do seu negócio!"
      canonical="https://itstecnologia.digital/totens-digitais/totens-tablet/smart-pay/"
    />
    <div className="main">

      <div className="section cover cover-page quarter-height totens-smart-pay-image">
        <div className="section caption">
          <div className="container">
            <div className="section title-section">
              <h1>Totens Tablet</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/" title="Totens Digitais">
                    Totens Digitais
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/totens-digitais/totens-tablet/" title="Totens Tablet">
                    Totens Tablet
                  </Link>
                </li>
                <li className="breadcrumb-item active">Smart Pay</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg about-content p-bottom-70 no-bg-img">
        <div className="container">
          <h2>Smart Pay</h2>
          <div className="row gutters">
            <div className="col-12 toten-explanation">
              <p>
                Os totens de autoatendimento Smart Pay, são totens de pagamento e possuem a mesma versatilidade dos
                totens Smart.
                </p>
              <p>
                A <span>diferença</span> básica do totem Smart, é que o totem tablet Smart Pay, disponibiliza uma
                    base também conhecida como berço, para acoplar um<span> equipamento eletrônico pin-pad</span>,
            tornando-o um terminal de pagamento ou também conhecido como totem de autopagamento.
                </p>
              <p>
                Os dispositivos utilizados nos totens tablet Smart Pay são <span> tablets Android e Ipad</span>.
                A comunicação entre o dispositivo tablets e o pin-pad é feita através de bluetooth.
                </p>
              <p>
                O totem de autoatendimento Smart Pay beneficia-se das mesmas <span>versatilidades</span> conhecidas
                dos totem
                Smart: ocupa pouco espaço, é fácil de transportar, permite a aplicação de identidade visual da
                    empresa, evento, campanhas, é <span>seguro e tem custo baixo</span>.
                </p>
              <p>
                A versatilidade dos totens tablets associado a possibilidade de anexar um equipamento eletrônico
                pin-pad, flexibiliza ainda mais o totem Smart Pay, tornando-o um totem completo para pagamentos.
                </p>
            </div>
            <div className="col-12">
              <h2 className="title-alt">Destaques</h2>
              <ul className="check-list-structure feat">
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Base/Berço para pin-pad;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Ocupam pouco espaço;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Fáceis de Transportar;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Resistentes;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Seguros;</span>
                </li>
                <li>
                  <SVG
                    className="check-icon"
                    src={require("../../assets/svg/check.svg")}
                  />
                  <span>Custo X Benefício;</span>
                </li>
              </ul>
            </div >
          </div >
        </div >
      </div >

      <div className="section gallery-content">
        <div className="container">
          <h2>Galeria</h2>
          <div className="gallery-row">
            <div className="gallery-col">
              <img title="Totem de autopagamento, demonstrativo" src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-01.jpg")} alt="Demonstrativo do totem de autopagamento com pinpad e pedestal." />
              <img title="Totem de autopagamento, com pinpad e pedestal " src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-02.jpg")} alt="Equipamentos de totem para pagamento com pinpad acoplado e pedestal." />
            </div>
            <div className="gallery-col">
              <img title="Totem de autopagamento, angulos diversos" src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-03.jpg")} alt="Três angulos do totem de pagamento com pinpad acoplado e chave no gabinete." />
              <img title="Totem de autopagamento, vista frontal" src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-04.jpg")} alt="Equipamento de totem de autopagamento com pinpad visto por outro ângulo." />
            </div>
            <div className="gallery-col">
              <img title="Totem de autopagamento, com pinpad acoplado" src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-05.jpg")} alt="Pinpad acoplado no totem de autopagamento." />
            </div>
            <div className="gallery-col">
              <img title="Totem de autopagamento, angulo lateral" src={require("../../assets/galleries/pay/totem-autopagamento-com-pinpad-06.jpg")} alt="Ângulo lateral do totem de autopagamento com pinpad acoplado." />
            </div>
          </div>
        </div>
      </div>

      <div className="section dark-bg padding-70 resources-content no-bg-img p-bottom-40">
        <div className="container">
          <div className="row">
            <h2>Características</h2>
          </div>
          <div className="row gutters">
            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/anti-impacto.png")} alt="complementary icons" />
              </div>
              <div className="content">
                <h3>Bivolt Automático</h3>
                <p>Pensando na variedade de ambientes, os equipamentos possuem fonte bivolt automática, se adaptando
                        a redes 110v ou 220v sem necessidade de ajuste manual.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/alto-desempenho.png")} alt="complementary icons" />
              </div>
              <div className="content">
                <h3>Flexibilidade</h3>
                <p>Por se tratar de um equipamento com sistema operacional Android e iOS, existe a possibilidade de
                        utilização de qualquer aplicativo mobile disponível na Google Play e Apple Store.</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 r-item">
              <div className="icon">
                <img src={require("../../assets/icons/bivolt.png")} alt="complementary icons" />
              </div>
              <div className="content">
                <h3>Suporte</h3>
                <p>Base em pedestal, podendo ser fixa ou com ventosas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section call-to-action-alt no-margin">
        <div className="mask">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>Entre em contato, traga seu projeto para a iTS!</h3>
              </div>
              <div className="col-12 col-md-4">
                <div className="outline-button">
                  <Link to="/contato/">
                    Entrar em contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section white-bg padding-70 resources-content">
        <div className="container">
          <h2 className="dark-red-color">Especificações Técnicas</h2>
          <table className="table-striped table">
            <thead>
              <tr>
                <th>Itens</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gabinete</td>
                <td>Metal, disponível na cor Preto ou Branca</td>
              </tr>
              <tr>
                <td>Proteções do gabinete</td>
                <td>Maresia, Estática e Corrosão</td>
              </tr>
              <tr>
                <td>Suporte</td>
                <td>Com pedestal</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>10 polegadas</td>
              </tr>
              <tr>
                <td>Touch Screen</td>
                <td>1 toque</td>
              </tr>
              <tr>
                <td>Dispositivo</td>
                <td>Compatível com tablets Android e iPAD</td>
              </tr>
              <tr>
                <td>Suporte mPOS</td>
                <td>Ingenico</td>
              </tr>
              <tr>
                <td>Comunicação Dispositivo e mPOS</td>
                <td>Bluetooth</td>
              </tr>
              <tr>
                <td>Pés</td>
                <td>Pés fixos<br />
                  Ventosas <small>(OPCIONAL)</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="section white-bg p-bottom-60">
        <div class="container">
          <div className="row">
            <div className="col-12 products-recall-title">
              Conheça Também:
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <Link to="/totens-digitais/totens-tablet/smart/">
                <div className="row product-wrapper recall h-240">
                  <div className="col-12 col-md-5">
                    <div>
                      <div className="product-box-recall">
                        <div className="logo-smart alt recall">
                          <SVG
                            className="default-icon"
                            src={require("../../assets/svg/smart.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="product-description recall">
                      <h3>Totens Smart</h3>
                      <p className="products-recall-description">
                        Totem Tablet com, ou sem, pedestal.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div >
  </Fragment >
);
