const believers = [
  {
    logo: require("../../assets/supporters/cocamar_logo.png"),
    url: "https://www.cocamar.com.br/",
    name: "Cocamar"
  },
  {
    logo: require("../../assets/supporters/condor_logo.png"),
    url: "https://www.condor.com.br/",
    name: "Condor"
  },
  {
    logo: require("../../assets/supporters/elotech_logo.png"),
    url: "https://www.elotech.com.br/",
    name: "Elotech"
  },
  {
    logo: require("../../assets/supporters/engerey_logo.png"),
    url: "http://www.engerey.com.br/",
    name: "Engerey"
  },
  {
    logo: require("../../assets/supporters/gazin_logo.png"),
    url: "https://www.gazin.com.br/",
    name: "Gazin"
  },
  {
    logo: require("../../assets/supporters/havan_logo.png"),
    url: "https://www.havan.com.br/",
    name: "Havan"
  },
  {
    logo: require("../../assets/supporters/imovel_web_logo.png"),
    url: "https://www.imovelweb.com.br/",
    name: "Imóvel Web"
  },
  {
    logo: require("../../assets/supporters/john_deere_logo.png"),
    url: "https://www.deere.com.br/pt/index.html",
    name: "John Deere"
  },
  {
    logo: require("../../assets/supporters/landis_logo.png"),
    url: "https://www.landisgyr.com.br/",
    name: "Landis"
  },
  {
    logo: require("../../assets/supporters/leroy_merlin_logo.png"),
    url: "https://www.leroymerlin.com.br/",
    name: "Leroy Merlin"
  },
  {
    logo: require("../../assets/supporters/mongeral_logo.png"),
    url: "https://www.mongeralaegon.com.br/",
    name: "Mongeral"
  },
  {
    logo: require("../../assets/supporters/rede_sagrado_sul_logo.png"),
    url: "http://www.redesagradosul.com.br/",
    name: "Rede Sagrado Sul"
  },
  {
    logo: require("../../assets/supporters/reymaster_logo.png"),
    url: "http://www.reymaster.com.br/",
    name: "Reymaster"
  },
  {
    logo: require("../../assets/supporters/iTS_Csd.png"),
    url: "https://www.sitemercado.com.br/supermercadoscidadecancao/maringa_loja-maringa-01-zona-05-avenida-brasil",
    name: "Supermercados Cidade Canção"
  },
  {
    logo: require("../../assets/supporters/unibrasil_logo.png"),
    url: "https://www.unibrasil.com.br/",
    name: "Unibrasil"
  },
  {
    logo: require("../../assets/supporters/youdo-logo.png"),
    url: "https://live.youdobrasil.com.br/",
    name: "YouDO"
  },
  // {
  //   logo: require("../../assets/supporters/bussola_logo.png"),
  //   url: "www.google.com",
  //   name: "Bússola"
  // },
  // {
  //   logo: require("../../assets/supporters/stone_logo.png"),
  //   url: "www.google.com",
  //   name: "Stone"
  // },
  // {
  //   logo: require("../../assets/supporters/strada_logo.png"),
  //   url: "www.google.com",
  //   name: "Strada"
  // },
];

export default believers;