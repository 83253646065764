import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "../../components";

const its_logo = require('../../assets/its-tecnologia-horizontal.png')

export default (props) => (
  <Fragment>
    <Helmet
      title="404"
      description="404"
      canonical="https://itstecnologia.digital/404/"
    />
    <div className="section">
      <div className="error-page-margin"></div>
      <div className="section error-parallax no-margin p-h-60">
        <div className="container">
          <div className="container error-container">
            <div className="row">
              <div className="col-12">
                <img title="Logo iTs Tecnologia" src={its_logo} alt="iTS Tecnologia"
                  height="140px" />
              </div>
              <div className="col-12">
                <h3 className="text-center">Oops...</h3>
              </div>
              <div className="col-12">
                <h2 className="toten-subtitle">A página que você está tentando acessar não existe ou foi movida.
                        </h2>
                <hr className="separator-line" />
              </div>
              <div className="col-12">
                <h1 className="text-center">404</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="container text-center">
                  <Link to="/" title="Página inicial">Voltar para a iTS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
