import React, { Component } from "react";
import { CasesTotensLausPage } from "../pages";

class CasesTotensLausController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "Laus",
        playStore: "https://play.google.com/store/apps/details?id=br.com.squarebits.lausgirl",
        appStore: "https://apps.apple.com/br/app/lausgirl-co/id1378379088",
        image: require("../assets/cases/laus-logo.webp"),
        category:
          "Desenvolvimento de aplicativos mobile, Desenvolvimento mobile, Desenvolvimento de app",
        segment: "Revendedores",
        solution:
          "Desenvolvimento de aplicativos mobile iOS e Android e painel administrativo e Catálogo de produtos"
      }
    };
  }

  render = () => <CasesTotensLausPage {...this.state} {...this.props} />;
}

export default CasesTotensLausController;
