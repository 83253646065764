import React, { Component } from "react";
import { CasesTotensTicNovaPage } from "../pages";

class CasesTotensTicNovaController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {
        title: "TicNova",
        image: require("../assets/supporters/ticnova_logo.png"),
        category: "Totem Digital, Totem de Autoatendimento, Totem Touchscreen, Totem Interativo, Totem Tablet, Desenvolvimento de aplicativo mobile, Desenvolvimento de app.",
        segment: "Eventos",
        solution: "Totem de autoatendimento com  desenvolvimento de aplicativo mobile de checkin, e desenvolvimento de aplicativo mobile de  pesquisa de satisfação."
      }
    };
  }

  render = () => <CasesTotensTicNovaPage {...this.state} {...this.props} />;
}

export default CasesTotensTicNovaController;
