import React from "react";
import Helmet from 'react-helmet'

export default (props) => (
  <Helmet>
    <title>{props.title}</title>
    <meta
      name="description"
      content={props.description}
    />
    {
      props.canonical
        ? <link rel="canonical" href={props.canonical} />
        : null
    }
  </Helmet>
);
